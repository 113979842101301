<template>
  <div>
    <!-- <div class="top1">
      <el-row>
        <el-col :span="6">
          今日学习时长
          <el-popover placement="top" width="210" popper-class="popover" trigger="hover" content="今日观看录播和直播回放的总时长">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </el-col>
        <el-col :span="6">
          累计学习时长
          <el-popover placement="top" width="185" popper-class="popover" trigger="hover" content="录播和直播回放的观看总时长">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </el-col>
        <el-col :span="6">连续学习天数</el-col>
        <el-col :span="6">完成课程数(截至昨日)</el-col>
      </el-row>
      <el-row>
        <el-col :span="6">30分钟</el-col>
        <el-col :span="6">
          93分58秒
          <template slot-scope="scope">
            <span v-if="parseInt(scope.row.play_duration / 60) > 0">{{ parseInt(scope.row.play_duration / 60) }}分钟</span>
            <span v-if="scope.row.play_duration % 60 > 0">{{ scope.row.play_duration % 60 }} 秒</span>
          </template>
        </el-col>
        <el-col :span="6">2</el-col>
        <el-col :span="6">19</el-col>
      </el-row>
    </div>-->

    <template>
      <div class="box-card">
        <div class="contian">
          <el-radio-group
            v-model="tabPosition"
            style="margin-bottom: 20px;float:left"
            @change="radiochang"
          >
            <el-radio-button label="4">录播</el-radio-button>
            <el-radio-button label="5">直播课回放</el-radio-button>
            <el-radio-button label="1">直播课-普通模式</el-radio-button>
            <el-radio-button label="2">直播课-极速模式</el-radio-button>
            <el-radio-button label="3">小班</el-radio-button>
            <el-radio-button label="6">直播</el-radio-button>
            <el-radio-button label="7">直播回放</el-radio-button>
          </el-radio-group>

          <el-select
            v-if="
              tabPosition == '4' || tabPosition == '5' || tabPosition == '1' || tabPosition == '6' || tabPosition == '7'
            "
            style="float:right"
            v-model="value"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-else
            style="float:right"
            v-model="value"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div style="clear:both"></div>
        <template>
          <record :type="Number(tabPosition)" :user_from="value" key="tab1" />
        </template>
      </div>
    </template>
    <router-view />
  </div>
</template>

<script>
import record from './record'
// import record2 from './record2'
// import wish from './wish' //敬请期待
// import TodayTableData from "./teachingAdmin/TodayTableData"; //今日访问

export default {
  props: {
    uid: {
      type: Number,
      default: 0
    }
  },
  components: {
    record
  },

  data() {
    return {
      options: [
        {
          value: 0,
          label: '全部终端'
        },
        {
          value: 1,
          label: '微信H5'
        },
        {
          value: 2,
          label: '小程序'
        },
        {
          value: 3,
          label: '电脑'
        }
      ],
      options2: [
        {
          value: 0,
          label: '全部终端'
        },
        {
          value: 1,
          label: ' 电脑'
        },
        {
          value: 2,
          label: '小程序'
        }
      ],
      value: 0,
      tabPosition: '4',
      count: [],
      showtag: [],
      isactive: -1,
      inputTag: '',
      newTags: false,
      dateList: [], //观看记录列表
      activeName: 'one', //默认全部
      type: 5 // 默认显示全部
    }
  },
  methods: {
    radiochang() {
      this.value = 0
    }
  }
}
</script>

<style lang="less" scoped>
// .active {
//   background: #0aa29bff;
//   color: white;
// }
// /deep/ .el-tag.el-tag--success {
//   width: 68px;
//   height: 28px;
//   border-radius: 4px;
//   border: 1px solid rgba(10, 162, 155, 1);
//   color: rgba(10, 162, 155, 1);
//   background: #fff;
// }
// /deep/ .el-tag.el-tag--info.el-tag--plain {
//   margin: 5px;
// }
// /deep/ .el-tag.el-tag--warning.el-tag--plain {
//   text-align: center;
//   border: 1px solid rgba(10, 162, 155, 1);
//   margin: 5px;
//   background: rgba(10, 162, 155, 1);
//   border-radius: 4px;
//   color: white;
// }

.contian {
  ::v-deep .el-input__inner {
    height: 36px;
    width: 120px;
  }
  ::v-deep .el-select .el-input__icon {
    line-height: 30px;
  }
}
.top1 {
  height: 67px;
  background-color: #fff;
  margin: 30px 20px 0;
  box-sizing: border-box;
  overflow: hidden;
  .el-row:first-child {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    line-height: 17px;
  }
  .el-row:last-child {
    margin-top: 10px;
    font-size: 22px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 29px;
  }
}
.box-card {
  background: rgba(255, 255, 255, 1);
  margin-top: 30px;
  /deep/ .el-radio-button__inner {
    width: 140px;
  }
}

/deep/ .el-tabs__header {
  .el-tabs__item {
    width: 131px;
    text-align: center;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
  }
}
/deep/.el-card__body {
  padding: 0px !important;
}
/deep/ .el-tabs__nav-wrap::after {
  height: 1px;
}
/deep/ .el-tabs__header.is-top {
  margin: 0px;
}
/deep/ .el-tabs__item.is-active {
  color: #0aa29b;
}
/deep/ .el-tabs__item:hover {
  color: #0aa29b;
}

/deep/ .el-tabs__active-bar {
  background-color: #0aa29b;
  height: 1px;
}
// /deep/ .el-tabs.el-tabs--top {
// }
/deep/ #tab-first {
  padding-left: 20px;
}

// .el-button:hover {
//   color: #fff !important;
//   border: 1px solid #1b9d97 !important;
//   background-color: #1b9d97 !important;
// }
// /deep/ .el-input.el-input--suffix {
//   width: 120px;
// }
/deep/ #tab-one {
  padding-left: 20px !important;
}
</style>
