<!--
 * @Author: cyy
 * @Date: 2021-12-02 18:08:07
 * @LastEditTime: 2021-12-02 18:13:36
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\teachingAdmin\detail\coursePurchas\OrderComponents\course.vue
-->
<template>
  <div>
    <template>
      <div style="margin: 20px 0px">
        <pagination2 ref="child" :option="post1" url="/PcUser/buyStatistics">
          <template
            v-slot:default="{
              tableData: { buy_count, exchange_count, order_amount, list },
            }"
          >
            <div class="top1">
              <el-row>
                <el-col :span="8">
                  <div class="flex">
                    <div>报名次数</div>
                    <helpIcon
                      style="margin-left: 6px"
                      prompt="学员累计报名课程次数，包括免费课程、已作废、已过期的课程，不包括兑换的课程"
                    ></helpIcon>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="flex">
                    <div>累计兑换课程</div>
                    <helpIcon
                      style="margin-left: 6px"
                      prompt="指累计兑换课程的数量，重复兑换一门课程也算一门"
                    ></helpIcon>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="flex">
                    <div>累计消费(元)</div>
                    <helpIcon
                      style="margin-left: 6px"
                      prompt="学员累计实际付费金额，包括已作废、已过期的课程"
                    ></helpIcon>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">{{ buy_count || 0 }}</el-col>
                <el-col :span="8">{{ exchange_count || 0 }}</el-col>
                <el-col :span="8">{{ order_amount || 0 }}</el-col>
              </el-row>
            </div>
            <div class="select">
              <el-select
                v-model="post1.status"
                style="width: 120px"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-table
              @sort-change="sortChange"
              @selection-change="handleSelectionChange"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="list"
              style="width: 100%"
            >
              <el-table-column type="selection" width="42"></el-table-column>
              <!--  优惠信息：无优惠、兑换码、优惠券、拼团 -->
              <!-- 类型： 直播课、录播课、系列课 -->
              <el-table-column
                label="课程名称"
                min-width="150px"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="{ row }">
                  <div class="course-info">
                    <div class="cover">
                      <lazy class="img" :src="row.course_photo"></lazy>
                    </div>
                    <div class="right">
                      <div class="title">{{ row.course_name }}</div>
                      <div class="type" v-if="row.price_type == 1">
                        ￥{{ row.price }}
                      </div>
                      <div class="type" v-if="row.price_type == 2">免费</div>
                      <div class="type" v-if="row.price_type == 3">
                        密码：{{ row.fee_pwd }}
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="类型" min-width="70">
                <template slot-scope="scope">
                  <span v-if="scope.row.course_type == 1">直播课</span>
                  <span v-if="scope.row.course_type == 2">小班课</span>
                  <span v-if="scope.row.course_type == 3">录播课</span>
                  <span v-if="scope.row.course_type == 4">系列课</span>
                </template>
              </el-table-column>
              <el-table-column
                label="实付金额(元)"
                min-width="100px"
                prop="courseprice"
              />
              <el-table-column
                label="营销活动"
                prop="activity_name"
              ></el-table-column>
              <el-table-column
                label="付款/失效时间"
                min-width="120"
                prop="validity"
              >
                <template slot="header">
                  <div class="flex-center" style="float: left">
                    <span>付款/失效时间</span>
                    <helpIcon
                      style="margin-left: 6px"
                      prompt="指学员观看课程的有效时间，创建课程时可设置有效期，也可以针对不同订单修改"
                    ></helpIcon>
                  </div>
                </template>

                <template slot-scope="{ row }">
                  <div>
                    {{ row.orderTime | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                  </div>
                  <div>
                    {{ row.validity | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="剩余(天)"
                min-width="100"
                sortable="custom"
              >
                <template slot-scope="{ row }">
                  <span v-if="row.days_remaining >= 0">
                    {{ row.days_remaining }}
                  </span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.status == 2" style="color: #46932c">
                    支付成功
                  </span>
                  <span v-if="scope.row.status == 3" class="redcolor">
                    已失效
                  </span>
                  <span v-if="scope.row.status == 4" class="redcolor">
                    已作废
                  </span>
                </template>
              </el-table-column>

              <el-table-column label="操作" fixed="right" min-width="130px">
                <template slot-scope="{ row }">
                  <div class="btns">
                    <div class="fl">
                      <span class="text" @click="detail(row)">详情</span>
                    </div>

                    <div v-if="row.status == 2" class="fl">
                      <el-divider direction="vertical"></el-divider>
                      <span class="text" @click="edit_time(row)">
                        编辑有效期
                      </span>
                      <el-divider direction="vertical"></el-divider>
                    </div>

                    <div class="fl" v-if="row.status == 2">
                      <span class="text" @click="delrecovery(row.order_id, 1)">
                        作废
                      </span>
                    </div>

                    <div v-if="row.status == 4" class="fl">
                      <el-divider direction="vertical"></el-divider>
                      <span class="text" @click="delrecovery(row.order_id, 2)">
                        恢复
                      </span>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
          <template v-slot:footer>
            <div class="footer">
              <span>已选择{{ selected.length || 0 }}条</span>
              <el-button
                class="ml20"
                type=""
                @click="zuofeis"
                :disabled="
                  selected.length > 0 && !isnobeselect && isstatus1 != 2
                "
                size="medium"
              >
                批量作废
              </el-button>
              <!-- <el-button
                type=""
                size="medium"
                :disabled="
                  selected.length > 0 && !isnobeselect && isstatus1 != 4
                "
                @click="huifus"
              >
                批量恢复
              </el-button> -->
              <el-button
                type=""
                size="medium"
                :disabled="
                  selected.length > 0 && !isnobeselect && isstatus1 != 2
                "
                @click="piliang"
              >
                批量编辑有效期
              </el-button>
            </div>
          </template>
        </pagination2>
        <el-dialog
          title="编辑有效期"
          :visible.sync="dialogVisible"
          width="600px"
        >
          <div class="fs14" slot="title">编辑有效期</div>
          <div class="dialog_body">
            <el-row>
              <el-col :span="8">学员：</el-col>
              <el-col :span="16">
                <span style="color: #333333">{{ uname }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">课程：</el-col>
              <el-col :span="16">
                <span style="color: #333333">{{ changetiem.course_name }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">订单有效期：</el-col>
              <el-col :span="16">
                <span class="redcolor">
                  {{
                    changetiem.validity | formatTimeStamp('yyyy-MM-dd hh:mm')
                  }}
                </span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">剩余：</el-col>
              <el-col :span="16">
                <span class="redcolor" v-if="changetiem.days_remaining">
                  {{ changetiem.days_remaining }}天
                </span>
                <span class="redcolor" v-else>
                  {{ changetiem.days_remaining }}天(已过期)
                </span>
              </el-col>
            </el-row>
            <el-row align="middle" type="flex">
              <el-col :span="8">修改有效期：</el-col>
              <el-col :span="16">
                <el-date-picker
                  style="width: 258px"
                  @change="editpicker"
                  v-model="eidtitme"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm"
                  value-format="timestamp"
                  placeholder="点击选择"
                  :picker-options="pickerOptions"
                ></el-date-picker>
                <span
                  class="redcolor ml20"
                  v-if="editpicker_surplus > 0"
                  style="line-height: 40px"
                >
                  剩余{{ editpicker_surplus }}天
                </span>
              </el-col>
            </el-row>
          </div>
          <span slot="footer" class="dialog-footer">
            <div
              v-if="changetiem.edit_time > 0"
              class="fl"
              style="margin-top: 5px"
            >
              <span class="mr30">
                最近修改：{{
                  changetiem.edit_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                }}
              </span>
              <span>操作人：{{ changetiem.editor }}</span>
            </div>
            <div class="fr">
              <el-button
                size="medium"
                style="width: 74px"
                @click="timepick_cancel"
              >
                取 消
              </el-button>
              <el-button
                size="medium"
                style="width: 74px"
                type="primary"
                @click="editime_submit"
              >
                确 定
              </el-button>
            </div>
            <div style="clear: both"></div>
          </span>
        </el-dialog>

        <el-dialog
          title="批量编辑有效期"
          :visible.sync="dialogVisible2"
          width="600px"
          class="dialogVisible2"
        >
          <div class="fs14" slot="title">批量编辑有效期</div>
          <el-row align="middle" type="flex">
            <el-col :span="5">观看有效期：</el-col>
            <el-col :span="19">
              <el-date-picker
                style="width: 258px"
                @change="editpicker_more"
                v-model="eidtitme_more"
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                value-format="timestamp"
                placeholder="点击选择时间"
                :picker-options="pickerOptions"
              ></el-date-picker>
              <span
                class="redcolor ml20"
                v-if="editpicker_surplus2 > 0"
                style="line-height: 40px"
              >
                剩余{{ editpicker_surplus2 }}天
              </span>
            </el-col>
          </el-row>
          <div class="dialogVisible2_font1">
            提示：保存后已选的订单的有效期会统一变更新设置的有效时间
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button size="medium" style="width: 74px" @click="more_cancel">
              取 消
            </el-button>
            <el-button
              size="medium"
              style="width: 74px"
              type="primary"
              @click="editime_submit_more"
            >
              确 定
            </el-button>
          </span>
        </el-dialog>
      </div>
    </template>
    <router-view />
  </div>
</template>

<script>
import helpIcon from '@/components/helpIcon'

export default {
  components: { helpIcon },

  // mixins: [background],

  data() {
    return {
      isstatus1: '',
      isnobeselect: '',
      pickerOptions: {
        disabledDate(time) {
          //time跟页面没有关系   相当于日期数组
          return time.getTime() < Date.now() - 8.64e7 //设置选择今天以及今天之后的日期
        },
      },
      validity3: 0,
      validity4: 0,
      changetiem: {},
      editpicker_surplus: 0, //单个编辑有效时间---选择时间计算的天数
      editpicker_surplus2: 0, //批量编辑有效时间---选择时间计算的天数
      eidtitme: '',
      eidtitme_more: '',
      dialogVisible: false, //单个编辑
      dialogVisible2: false, //批量编辑
      selected: [],
      post1: {
        uid: '',
        status: 1,
        order: '',
      },
      options: [
        {
          value: 1,
          label: '全部状态',
        },
        {
          value: 2,
          label: '支付成功',
        },
        {
          value: 4,
          label: '已作废',
        },
        {
          value: 3,
          label: '已失效',
        },
      ],
    }
  },
  props: {
    uname: [String],
  },
  created() {
    this.post1.uid = this.$route.query.uid
  },
  methods: {
    //批量作废订单
    zuofeis() {
      if (this.selected.length > 0) {
        if (this.isnobeselect) {
          this.$alert(
            '已选的订单包含了不同的状态，无法完成批量操作！如需批量操作，请勾选同一状态的订单；或者直接筛选出同一类订单',
            '温馨提示',
            {
              confirmButtonText: '知道了',
            }
          )
        } else {
          const ids = this.selected.map((f) => f.order_id)
          this.$confirm(
            '确定后，学员已购状态变为未购状态，是否作废订单？',
            '批量作废订单',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              closeOnClickModal: false,
            }
          ).then(() => {
            this.$http({
              url: '/Order/unsetOrder',
              data: {
                ids: ids,
                type: 1,
              },
              callback: () => {
                this.$root.prompt({
                  msg: '操作成功',
                  type: 'success',
                })
                this.selected = []
                this.$refs.child.reset()
              },
            })
          })
        }
      } else {
        this.$root.prompt('请先勾选列表数据')
      }
    },
    //批量恢复订单
    huifus() {
      if (this.selected.length > 0) {
        if (this.isnobeselect) {
          this.$alert(
            '已选的订单包含了不同的状态，无法完成批量操作！如需批量操作，请勾选同一状态的订单；或者直接筛选出同一类订单',
            '温馨提示',
            {
              confirmButtonText: '知道了',
            }
          )
        } else {
          const ids = this.selected.map((f) => f.order_id)
          this.$confirm('是否恢复已作废学员的订单？', '批量恢复订单', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
          }).then(() => {
            this.$http({
              url: '/Order/unsetOrder',
              data: {
                ids: ids,
                type: 2,
              },
              callback: () => {
                this.$root.prompt({
                  msg: '操作成功',
                  type: 'success',
                })
                this.selected = []
                this.$refs.child.reset()
              },
              error: (error) => {
                if (error.code == 205) {
                  this.$root.prompt(error.info)
                }
              },
            })
          })
        }
      } else {
        this.$root.prompt('请先勾选列表数据')
      }
    },

    more_cancel() {
      this.dialogVisible2 = false
      this.eidtitme_more = ''
      this.editpicker_surplus2 = 0
    },
    //批量编辑有效期
    editime_submit_more() {
      const ids = this.selected.map((f) => f.order_id)
      this.$http({
        url: '/order/setValidity',
        data: {
          ids: ids,
          validity: this.validity4,
        },
        callback: () => {
          this.$root.prompt({
            msg: '操作成功',
            type: 'success',
          })
          this.dialogVisible2 = false
          this.eidtitme_more = ''
          this.editpicker_surplus2 = 0
          this.selected = []
          this.validity4 = 0
          this.$refs.child.reset()
        },
      })
    },
    //单个编辑有效期取消
    timepick_cancel() {
      this.dialogVisible = false
      this.eidtitme = ''
      this.editpicker_surplus = 0
    },
    //单个编辑有效期
    editime_submit() {
      this.$http({
        url: '/order/setValidity',
        data: {
          ids: this.changetiem.order_id,
          validity: this.validity3,
        },
        callback: () => {
          this.$root.prompt({
            msg: '操作成功',
            type: 'success',
          })
          this.eidtitme = ''
          this.editpicker_surplus = 0
          this.dialogVisible = false
          this.validity3 = 0
          this.$refs.child.reset()
        },
      })
    },
    editpicker(val) {
      var time = new Date().valueOf()
      this.editpicker_surplus = Math.ceil((val - time) / (1000 * 60 * 60 * 24))
      this.validity3 = val / 1000
    },
    editpicker_more(val) {
      var time = new Date().valueOf()
      this.editpicker_surplus2 = Math.ceil((val - time) / (1000 * 60 * 60 * 24))
      this.validity4 = val / 1000
    },
    //订单详情
    detail(row) {
      this.$router.push({
        path: `/capitalOrder/detail`,
        query: {
          data_id: row.order_id,
        },
      })
    },
    //单个编辑有效期
    edit_time(val) {
      this.changetiem = val
      this.eidtitme = ''
      this.editpicker_surplus = 0
      this.dialogVisible = true
    },

    piliang() {
      if (this.selected.length > 0) {
        if (this.isnobeselect) {
          this.$alert(
            '已选的订单包含了不同的状态，无法完成批量操作！如需批量操作，请勾选同一状态的订单；或者直接筛选出同一类订单',
            '温馨提示',
            {
              confirmButtonText: '知道了',
            }
          )
        } else {
          this.eidtitme_more = ''
          this.editpicker_surplus2 = 0
          this.dialogVisible2 = true
        }
      } else {
        this.$root.prompt('请先勾选列表数据')
      }
    },
    sortChange(value) {
      this.post1 = _.assign({}, this.post1, {
        order: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
      })
    },

    handleSelectionChange(val) {
      this.selected = val
      const isfist = val[0] ? val[0].status : ''
      if (isfist && val.every((item) => item.status == isfist)) {
        this.isnobeselect = false
        this.isstatus1 = val[0].status
      } else {
        this.isnobeselect = true
        this.isstatus1 = ''
      }
    },
    delrecovery(val1, val2) {
      if (val2 == 1) {
        this.$confirm(
          '作废后学员已购状态变为未购状态，是否作废订单？',
          '作废订单',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {
          this.$http({
            url: '/Order/unsetOrder',
            data: {
              ids: [val1],
              type: val2,
            },
            callback: () => {
              this.$root.prompt({
                msg: '作废成功',
                type: 'success',
              })
              this.$refs.child.reset()
            },
            error: () => {
              this.$root.prompt('作废失败')
            },
          })
        })
      } else {
        this.$confirm('是否恢复该订单？', '恢复订单', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.$http({
            url: '/Order/unsetOrder',
            data: {
              ids: [val1],
              type: val2,
            },
            callback: () => {
              this.$root.prompt({
                msg: '恢复成功',
                type: 'success',
              })
              this.$refs.child.reset()
            },
            error: (error) => {
              if (error.code == 205) {
                this.$root.prompt(error.info)
              }
            },
          })
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .el-divider {
  background-color: #1b9d97 !important;
}
.dialogVisible2 {
  & ::v-deep .el-dialog__body {
    height: 200px;
  }
  .dialogVisible2_font1 {
    font-size: 12px;
    margin-top: 18px;
    color: #999999;
    line-height: 20px;
  }
}
.dialog_body {
  .el-row {
    margin-bottom: 20px;
  }
}
.dialog-footer {
  span {
    font-size: 13px;
    color: #666666;
    line-height: 13px;
  }
}
.btns {
  // @extend %btns;
  color: #1b9d97;
  span {
    cursor: pointer;
  }
}
.footer {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-bottom: 10px;

  .el-button {
    width: 136px;
    margin-top: 20px;
  }
}

.top1 {
  height: 67px;
  background-color: #fff;
  // margin: 30px 20px 0;
  margin-top: 30px;
  box-sizing: border-box;
  overflow: hidden;
  ::v-deep .el-row {
    .el-col {
      .flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 13px;
        color: rgba(102, 102, 102, 1);
        line-height: 17px;
      }
      .number {
        font-size: 22px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        line-height: 29px;
        margin-top: 9px;
      }
    }
  }
  .el-row:first-child {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    line-height: 17px;
  }
  .el-row:last-child {
    margin-top: 10px;
    font-size: 22px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 29px;
  }
}

.select {
  .el-select {
    float: right;
    margin-bottom: 20px;
  }
  /deep/ .el-input--suffix .el-input__inner {
    height: 36px;
  }
}
.course-info {
  display: flex;
  align-items: center;
  .cover {
    width: 68px;
    height: 38px;
    position: relative;
    background: grey;
    .img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    flex: 1;
    overflow: hidden;
    margin-left: 8px;
    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: rgba(51, 51, 51, 1);
    }
    .type {
      color: red;
      height: 18px;
      font-size: 12px;
    }
  }
}
</style>
