<!--
 * @Author: cyy
 * @Date: 2021-12-02 18:36:08
 * @LastEditTime: 2022-07-29 18:00:20
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\teachingAdmin\detail\coursePurchas\OrderComponents\opencourse.vue
-->
<template>
  <div id="opencassOrder">
    <template>
      <div class="header2">
        <div class="left">
          <span>累计订单收入(元)：{{ find.price }}</span>
          <span class="ml50">累计订单(笔)：{{ find.all_count }}</span>
        </div>
      </div>
      <pagination2
        @complete="complete"
        ref="child"
        :option="post"
        url="/openClass/openClassOrder"
      >
        <template v-slot:default="{ tableData: { list } }">
          <el-table
            :data="list"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <el-table-column label="订单号" prop="number"></el-table-column>

            <el-table-column label="付款学员" min-width="100px">
              <template slot-scope="{ row }">
                <div class="paymember">
                  <div class="member_photo">
                    <img :src="row.uphoto" alt="" />
                  </div>
                  <div
                    class="font1"
                    :title="
                      `${row.uname}${
                        !row.student_remarks
                          ? ''
                          : '(' + row.student_remarks + ')'
                      }`
                    "
                  >
                    {{ row.uname }}
                    <span v-if="row.student_remarks">
                      ({{ row.student_remarks }})
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              label="课程"
              min-width="160px"
              prop="open_class_name"
            ></el-table-column>

            <el-table-column label="售价(元)">
              <template slot-scope="{ row }">
                ￥{{ row.selling_price }}
              </template>
            </el-table-column>

            <el-table-column label="实付(元)" prop="price">
              <template slot-scope="{ row }">￥{{ row.price }}</template>
            </el-table-column>

            <el-table-column label="付款时间" prop="order_time">
              <template slot-scope="{ row }">
                {{ row.order_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
              <!-- formatTimeStamp('yyyy-MM-dd hh:mm') -->
            </el-table-column>

            <el-table-column label="状态" prop="costprice">
              <template slot-scope="{ row }">
                <span v-if="row.status == 1" style="color: #ff3535">
                  未支付
                </span>
                <span v-else style="color: #46932c">支付成功</span>
                <!-- 支付成功 、已过期 -->
              </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right">
              <template slot-scope="{ row }">
                <div class="btns">
                  <el-button
                    type="text"
                    @click="
                      $router.push({
                        path:
                          '/capitalOrder/opencourseDetail/' +
                          row.open_class_order_id,
                      })
                    "
                  >
                    详情
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </template>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'opencassOrder',
  data() {
    return {
      find: {},
      post: {
        uid: this.uid,
        search_type: 1, //    搜索条件  1订单号  2学员
        search: '',
      },

      options3: [
        {
          value: 1,
          label: '订单号',
        },
        {
          value: 2,
          label: '付款学员',
        },
      ],
    }
  },
  props: {
    uid: [String, Number],
  },
  methods: {
    //获取列表的数据
    complete(val) {
      this.find = val.data.find
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .input-with-select .el-input__inner {
  width: 218px !important;
}
::v-deep .search_select {
  .el-input__inner {
    width: 120px !important;
  }
}
#opencassOrder {
  .sp_diver {
    margin: 0 10px;
    color: #1b9d97;
  }
  .header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .right {
      display: flex;

      ::v-deep .input-with-select {
        .el-input__inner {
          color: #333333;
        }
        .el-input-group__prepend {
          background-color: #fff;
        }
      }
    }
  }

  .header2 {
    margin-bottom: 10px;
    .left {
      font-size: 14px;
      color: #666666;
      line-height: 14px;
    }
  }

  .membership {
    display: flex;
    align-items: center;
    .membership_photo {
      margin-right: 10px;
      img {
        width: 64px;
        height: 36px;
      }
    }
    .font1 {
      width: calc(100% - 74px);
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .paymember {
    display: flex;
    align-items: center;
    .member_photo {
      margin-right: 10px;
      img {
        width: 24px;
        height: 24px;
        border-radius: 12px;
      }
    }
    .font1 {
      width: calc(100% - 34px);
      font-size: 12px;
      font-weight: 500;
      color: #333333;
      line-height: 17px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .btns {
    display: flex;
    align-items: center;
  }
  .el-input {
    width: 338px;
  }

  ::v-deep .el-input--medium .el-input__inner {
    width: 120px;
  }
}
</style>
