<template>
  <div>
    <template>
      <div style="margin: 20px 0px">
        <el-tabs v-model="activeName" class="mb30">
          <el-tab-pane label="课程订单" name="1"></el-tab-pane>

          <el-tab-pane label="会员订单" name="2"></el-tab-pane>

          <el-tab-pane label="资料订单" name="3"></el-tab-pane>

          <el-tab-pane label="公开课订单" name="4"></el-tab-pane>

          <el-tab-pane label="代金券订单" name="5"></el-tab-pane>

          <el-tab-pane label="商品订单" name="6"></el-tab-pane>

          <el-tab-pane label="图文订单" name="7"></el-tab-pane>
        </el-tabs>

        <template v-if="activeName == '1'">
          <course :uname="uname"></course>
        </template>

        <template v-if="activeName == '2'">
          <member v-if="activeName == '2'" :uid="uid"></member>
        </template>

        <template v-if="activeName == '3'">
          <dataorder :uid="uid"></dataorder>
        </template>

        <template v-if="activeName == '4'">
          <opencourse :uid="uid"></opencourse>
        </template>

        <template v-if="activeName == '5'">
          <cashcoupon :uid="uid" :type="1" :key="activeName"></cashcoupon>
        </template>

        <template v-if="activeName == '6'">
          <cashcoupon :uid="uid" :type="2" :key="activeName"></cashcoupon>
        </template>

        <template v-if="activeName == '7'">
          <graphic :uid="uid" :key="activeName"></graphic>
        </template>
      </div>
    </template>
    <router-view />
  </div>
</template>

<script>
import member from './OrderComponents/member'
import course from './OrderComponents/course'
import dataorder from './OrderComponents/data'
import opencourse from './OrderComponents/opencourse'
import cashcoupon from './OrderComponents/cashcoupon'
import graphic from './OrderComponents/graphic'

export default {
  components: { course, member, dataorder, opencourse, cashcoupon, graphic },

  data() {
    return {
      activeName: '1',
    }
  },
  props: {
    uname: [String],
  },
  created() {},
  computed: {
    uid() {
      return this.$route.query.uid
    },
  },
  methods: {},
}
</script>

<style lang="less" scoped>
::v-deep .el-divider {
  background-color: #1b9d97 !important;
}
.dialogVisible2 {
  & ::v-deep .el-dialog__body {
    height: 200px;
  }
  .dialogVisible2_font1 {
    font-size: 12px;
    margin-top: 18px;
    color: #999999;
    line-height: 20px;
  }
}
.dialog_body {
  .el-row {
    margin-bottom: 20px;
  }
}
.dialog-footer {
  span {
    font-size: 13px;
    color: #666666;
    line-height: 13px;
  }
}
.btns {
  // @extend %btns;
  color: #1b9d97;
  span {
    cursor: pointer;
  }
}
.footer {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-bottom: 10px;

  .el-button {
    width: 136px;
    margin-top: 20px;
  }
}

.top1 {
  height: 67px;
  background-color: #fff;
  // margin: 30px 20px 0;
  margin-top: 30px;
  box-sizing: border-box;
  overflow: hidden;
  ::v-deep .el-row {
    .el-col {
      .flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 13px;
        color: rgba(102, 102, 102, 1);
        line-height: 17px;
      }
      .number {
        font-size: 22px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        line-height: 29px;
        margin-top: 9px;
      }
    }
  }
  .el-row:first-child {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    line-height: 17px;
  }
  .el-row:last-child {
    margin-top: 10px;
    font-size: 22px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 29px;
  }
}

.select {
  .el-select {
    float: right;
    margin-bottom: 20px;
  }
  /deep/ .el-input--suffix .el-input__inner {
    height: 36px;
  }
}
.course-info {
  display: flex;
  align-items: center;
  .cover {
    width: 68px;
    height: 38px;
    position: relative;
    background: grey;
    .img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    flex: 1;
    overflow: hidden;
    margin-left: 8px;
    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: rgba(51, 51, 51, 1);
    }
    .type {
      color: red;
      height: 18px;
      font-size: 12px;
    }
  }
}
</style>
