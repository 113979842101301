<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-08-02 18:41:04
 * @LastEditTime: 2022-09-07 17:55:12
 * @LastEditors: cyy
 * @Description: 图文
 * @FilePath: \teacherdingding.com\src\views\teachingAdmin\detail\coursePurchas\OrderComponents\graphic.vue
-->
<template>
  <div class="saleOrder">
    <div class="top">
      <div class="search">
        <el-form
          ref="ruleIngredientsForm"
          label-width="70px"
          :model="search"
          class="flex-center"
          size="small"
        >
          <el-form-item label="关键词" class="mr20">
            <div class="search-item search-item1 ">
              <div class="vm select">
                <el-select v-model="search.search_type" size="medium">
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="vm input">
                <el-input
                  :placeholder="placeholder"
                  v-model="search.search"
                  size="medium"
                  clearable
                ></el-input>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="付款时间" class="mr20">
            <div class="vm select ">
              <el-date-picker
                style="width:360px"
                v-model="search.order_time"
                @change="
                  selectedTime(arguments[0], [
                    'start_order_time',
                    'end_order_time',
                  ])
                "
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                unlink-panels
                value-format="timestamp"
                :default-time="['00:00:00', '23:59:59']"
                size="medium"
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-button
            size="medium"
            type="primary"
            style="margin-bottom:18px"
            @click="toSearch"
          >
            搜索
          </el-button>
        </el-form>
      </div>
    </div>
    <pagination2
      :option="search2"
      class="childDialogBox"
      url="/Graphic/buyOrder"
      ref="childDialogBox"
    >
      <template v-slot:default="{ tableData: { list } }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="list"
        >
          <el-table-column label="订单号" prop="number"></el-table-column>

          <el-table-column label="用户" min-width="100px">
            <template slot-scope="{ row }">
              <div class="user_contain">
                <div class="tu">
                  <img :src="row.uphoto" alt="" />
                </div>
                <div class="name">{{ row.uname }}</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="手机号">
            <template slot-scope="{ row }">{{ row.umobile || '-' }}</template>
          </el-table-column>
          <el-table-column label="售价">
            <template slot-scope="{ row }">
              {{ row.selling_price }}
            </template>
          </el-table-column>
          <el-table-column label="实付">
            <template slot-scope="{ row }">
              {{ row.price }}
            </template>
          </el-table-column>
          <el-table-column label="付款时间" width="180px">
            <template slot-scope="{ row }">
              {{ row.order_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="{ row }">
              {{ row.status == 1 ? '未支付 ' : '支付成功' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" min-width="100px">
            <template slot-scope="{ row }">
              <el-button
                type="text"
                @click="
                  $router.push({
                    path: '/capitalOrder/graDetails',
                    query: { id: row.g_order_id },
                  })
                "
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
  </div>
</template>

<script>
export default {
  name: 'saleOrder',

  data() {
    return {
      fieldsList: [],

      search: {
        search: '',
        status: 2,
        order_time: [],
        search_type: 1,
        start_order_time: '',
        end_order_time: '',
      },

      search2: {},

      // 搜索-
      options1: [
        { label: '用户名', value: 1, placeholder: '输入用户名' },
        { label: '手机号', value: 2, placeholder: '输入手机号' },
      ],

      options2: [
        { label: '全部', value: 0 },
        { label: '待审核', value: 1 },
        { label: '已拒绝', value: 2 },
      ],
    }
  },

  props: {
    uid: [String, Number],
  },

  computed: {
    // 昵称 真实姓名 手机号码 切换
    placeholder() {
      return this.options1.find(item => item.value == this.search.search_type)
        .placeholder
    },
  },

  created() {
    this.search2 = _.assign({}, this.search, { uid: this.uid })
  },

  methods: {
    // 搜索
    toSearch() {
      this.search2 = _.assign({}, this.search, { uid: this.uid })
    },

    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.search[contextName[index]] = item / 1000
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.saleOrder {
  .top {
    margin-bottom: 28px;

    .search {
      margin-top: 28px;
      display: flex;
      justify-content: flex-end;
      .middle-space {
        font-size: 14px;
        line-height: 19px;
        margin: 0 10px;
        color: rgba(51, 51, 51, 1);
      }
      ::v-deep .el-form {
        flex-wrap: wrap;
      }
      .search_type {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .el-form-item__content {
          height: auto;
        }
        .el-form-item {
          width: 33.3%;
          margin-bottom: 20px;
        }
      }
      ::v-deep .el-input,
      ::v-deep .select {
        width: 100%;
        .el-input__inner {
          width: 100%;
        }
      }
      ::v-deep .search-item1 {
        display: flex;
        width: 300px;
        .select {
          width: 130px;
          input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .input {
          width: 100%;
          input {
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
      ::v-deep .search-item2,
      ::v-deep .search-item6 {
        .select {
          width: 160px;
        }
      }
      ::v-deep .search-item3,
      ::v-deep .search-item4 {
        .el-date-editor {
          width: 292px;
        }
        .el-input__icon {
          line-height: 27px;
        }
      }
      ::v-deep .search-item5,
      ::v-deep .search-item7,
      ::v-deep .search-item8 {
        width: 300px;
        .el-input {
          // width: 130px;
          position: relative;
          input[type='number'] {
            padding-right: 50px;
          }
          .el-input-group__append {
            position: absolute;
            right: 0;
            background: none;
            top: 6px;
            height: 100%;
            border: 0;
            // line-height: 36px;
            width: auto;
            padding: 0;
            padding-right: 10px;
          }
        }
      }
    }
  }
  .childDialogBox {
    .user_contain {
      display: flex;
      align-content: center;
      .tu {
        width: 28px;
        height: 28px;
        background: #939191;
        border-radius: 14px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
      }
    }
  }
}
</style>
