<!--
 * @Author: cyy
 * @Date: 2021-12-02 18:23:32
 * @LastEditTime: 2021-12-02 18:27:21
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\teachingAdmin\detail\coursePurchas\OrderComponents\member.vue
-->
<template>
  <div id="member">
    <template>
      <div class="header2">
        <div class="left">
          <span>累计订单收入(元)：{{ find.price }}</span>
          <span class="ml50">累计订单(笔)：{{ find.all_count }}</span>
        </div>
      </div>
      <pagination2
        @complete="complete"
        ref="child"
        :option="post"
        url="/courseMember/order"
      >
        <template v-slot:default="{ tableData: { list } }">
          <el-table
            @sort-change="sortChange"
            :data="list"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <el-table-column
              label="订单号"
              prop="order_number"
            ></el-table-column>
            <el-table-column label="付款学员" min-width="100px">
              <template slot-scope="{ row }">
                <div class="paymember">
                  <div class="member_photo">
                    <img :src="row.uphoto" alt="" />
                  </div>
                  <div
                    class="font1"
                    :title="`${row.uname}${
                      !row.student_remarks
                        ? ''
                        : '(' + row.student_remarks + ')'
                    }`"
                  >
                    {{ row.uname }}
                    <span v-if="row.student_remarks">
                      ({{ row.student_remarks }})
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="会员名称" min-width="160px">
              <template slot-scope="{ row }">
                <div class="membership">
                  <div class="membership_photo">
                    <img :src="row.member_cover_img" alt="" />
                  </div>
                  <div class="font1" :title="row.member_name">
                    {{ row.member_name }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="类型">
              <template slot-scope="{ row }">
                <span v-if="row.order_type == 1">用户购买</span>
                <span v-else>手动设置</span>
              </template>
            </el-table-column>
            <el-table-column label="规格" prop="costprice">
              <template slot-scope="{ row }">
                {{ row.member_vaild_day }}天
              </template>
            </el-table-column>
            <el-table-column label="实付" prop="costprice">
              <template slot-scope="{ row }">￥{{ row.actual_price }}</template>
            </el-table-column>

            <el-table-column
              label="下单时间"
              prop="costprice"
              sortable="custom"
              min-width="100px"
            >
              <template slot-scope="{ row }">
                {{ row.order_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
              <!-- formatTimeStamp('yyyy-MM-dd hh:mm') -->
            </el-table-column>
            <el-table-column label="状态" prop="costprice">
              <template slot-scope="{ row }">
                <span v-if="row.status == 1" style="color: #ff3535">
                  未支付
                </span>
                <span v-else style="color: #2791fb">支付成功</span>
                <!-- 支付成功 、已过期 -->
              </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right">
              <template slot-scope="{ row }">
                <div class="btns">
                  <el-button
                    type="text"
                    @click="
                      $router.push({
                        path: '/capitalOrder/detail',
                        query: {
                          member_order_id: row.member_order_id,
                          type: 2,
                        },
                      })
                    "
                  >
                    详情
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </template>
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'member',
  data() {
    return {
      find: {},
      post: {
        uid: this.uid,
        status: 0, // 状态 0 全部状态  1未支付  2支付成功
        order_type: 0, //类型 0全部类型  1用户购买  2手动设置
        sort: '',
        type: 1, //    搜索条件  1会员名称  2学员
        search: '',
      },
    }
  },
  props: {
    uid: [String, Number],
  },
  methods: {
    sortChange(value) {
      this.post.sort = !value.order ? '' : value.order === 'ascending' ? 1 : 2
    },
    //获取列表的数据
    complete(val) {
      this.find = val.data.find
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .input-with-select .el-input__inner {
  width: 218px !important;
}
::v-deep .search_select {
  .el-input__inner {
    width: 120px !important;
  }
}
#member {
  .header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .right {
      display: flex;

      ::v-deep .input-with-select {
        .el-input__inner {
          color: #333333;
        }
        .el-input-group__prepend {
          background-color: #fff;
        }
      }
    }
  }

  .header2 {
    margin-bottom: 10px;
    .left {
      font-size: 14px;
      color: #666666;
      line-height: 14px;
    }
  }

  .membership {
    display: flex;
    align-items: center;
    .membership_photo {
      margin-right: 10px;
      img {
        width: 64px;
        height: 36px;
      }
    }
    .font1 {
      width: calc(100% - 74px);
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .paymember {
    display: flex;
    align-items: center;
    .member_photo {
      margin-right: 10px;
      img {
        width: 24px;
        height: 24px;
        border-radius: 12px;
      }
    }
    .font1 {
      width: calc(100% - 34px);
      font-size: 12px;
      font-weight: 500;
      color: #333333;
      line-height: 17px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .btns {
    display: flex;
    align-items: center;
  }
  .el-input {
    width: 338px;
  }

  ::v-deep .el-input--medium .el-input__inner {
    width: 120px;
  }
}
</style>
