<template>
  <div id="questionnaireRecord">
    <template>
      <div style="margin: 20px 0">
        <pagination2 :option="post1" url="/PcUser/getNaireList">
          <template v-slot:default="{ tableData }">
            <el-table
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="tableData"
              style="width: 100%"
            >
              <el-table-column label="调查表名称" prop="naire_name" />
              <el-table-column label="填写时间">
                <template slot-scope="scope">
                  {{
                    scope.row.submit_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                  }}
                </template>
              </el-table-column>
              <el-table-column label="采集方式">
                <template slot-scope="{ row }">
                  {{ row.type | getmodefl }}
                </template>
              </el-table-column>
              <el-table-column label="触发行为" prop="source">
                <template slot-scope="{ row }">
                  <div v-if="row.type == 1">
                    <div v-if="row.naire_position == 1">登录后</div>
                    <div v-else-if="row.naire_position == 2">报名时</div>
                    <div v-else-if="row.naire_position == 3">兑换课程时</div>
                    <div v-else-if="row.naire_position == 4">购买会员时</div>
                    <div v-else-if="row.naire_position == 5">报名后</div>
                  </div>
                  <div v-else>-</div>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="detail(scope.row)">
                    查看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </pagination2>
        <el-dialog
          :title="data1.naire_name"
          :visible.sync="dialogTableVisible"
          :close-on-click-modal="false"
          width="40%"
          top="6vh"
        >
          <div class="zi1">{{ data1.description }}</div>
          <div class="tow">
            <div class="left">
              <div class="tu1"><img :src="data1.uphoto" /></div>
              <div class="zi2">{{ data1.uname }}</div>
            </div>
            <div class="right" v-if="data1.submit_time != 0">
              填写时间：
              {{ data1.submit_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
            </div>
            <div class="right" v-if="data1.submit_time == 0">--</div>
          </div>
          <div class="clear"></div>
          <div class="auto">
            <div class="bottom" v-for="(i, index) in data1.list" :key="index">
              <dl v-if="i.qtype == 1">
                <dt>
                  {{ index + 1 }}.{{ i.qname }}
                  <span v-if="i.not_null == 1" style="color: red">*</span>
                </dt>
                <dd v-if="i.have_result == 1">{{ i.result }}</dd>
                <dd v-if="i.have_result == 0">无</dd>
              </dl>
              <dl v-if="i.qtype == 2">
                <dt>
                  {{ index + 1 }}.{{ i.qname }}
                  <span v-if="i.not_null == 1" style="color: red">*</span>
                </dt>
                <dd v-if="i.have_result == 1">{{ i.result }}</dd>
                <dd v-if="i.have_result == 0">无</dd>
              </dl>
              <dl v-if="i.qtype == 3">
                <dt>
                  {{ index + 1 }}.{{ i.qname }}
                  <span style="color: red" v-if="i.not_null == 1">*</span>
                </dt>
                <ul v-if="i.have_result == 1">
                  <li v-for="item in i.result" :key="item">
                    <dd>{{ item }}</dd>
                  </li>
                </ul>
                <dd v-if="i.have_result == 0">无</dd>
              </dl>
              <div style="clear: both"></div>
            </div>
          </div>
        </el-dialog>
      </div>
    </template>
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      dialogTableVisible: false,
      post1: {
        uid: '',
      },
      data1: {
        list: [],
        uname: '',
        uphoto: '',
        description: '',
        naire_name: '',
        submit_time: '',
      },
    }
  },
  created() {
    this.post1.uid = this.$route.query.uid
  },

  filters: {
    getmodefl(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '行为触发采集'

          break

        default:
          text = '链接分享采集'
          break
      }
      return text
    },
  },

  methods: {
    //查看
    detail(val) {
      this.dialogTableVisible = true
      this.$http({
        url: 'PcSurvey/resultDetail',
        data: {
          naire_id: val.naire_id,
          uid: this.$route.query.uid,
          submit_id: val.submit_id,
        },
        callback: ({ data, code }) => {
          if (code == 200) {
            this.data1.list = data.question_info
            this.data1.uname = data.uname
            this.data1.uphoto = data.uphoto
            this.data1.description = data.description
            this.data1.naire_name = data.naire_name
            this.data1.submit_time = data.submit_time
          }
        },
        error: (error) => {
          if (error.code == 204) {
            this.list = []
          }
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.el-dialog__wrapper {
  .el-dialog {
    .zi1 {
      color: #666666ff;
      line-height: 20px;
      margin-right: 10px;
    }
    .tow {
      overflow: hidden;
      height: 42px;
      padding-top: 14px;
      border-bottom: 1px solid #e6e6e6ff;
      margin-bottom: 30px;
      .left {
        float: left;
        .tu1 {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          float: left;
          background-color: grey;
          margin-right: 10px;
          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }
        }
        .zi2 {
          margin-top: 3px;
          font-size: 14px;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          line-height: 17px;
          float: left;
        }
      }
      .right {
        float: right;
        font-size: 12px;
        color: rgba(153, 153, 153, 1);
        line-height: 16px;
      }
    }
    .auto {
      height: 463px;
      overflow-y: auto;
      .bottom {
        dt {
          margin-bottom: 10px;
          font-size: 15px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          line-height: 20px;
        }
        ul li {
          float: left;
          margin-right: 15px;
        }
        dd {
          margin-bottom: 25px;
          font-size: 13px;
          color: rgba(102, 102, 102, 1);
          line-height: 17px;
        }
      }
    }
  }
}
</style>
