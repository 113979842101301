var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promotionRecord"},[[_c('div',{staticClass:"headerMsg"},[_c('span',[_vm._v("累计订单收入(元)："+_vm._s(_vm.headerInfo.price))]),_c('span',[_vm._v("累计订单(笔)："+_vm._s(_vm.headerInfo.all_count))])]),_c('div',{staticClass:"contentVideo"},[_c('div',{staticClass:"rightMenu"},[_c('div',{staticClass:"rightCon"},[_c('pagination2',{ref:"childBackRecord",attrs:{"option":_vm.postRecord,"url":"/electronicsData/order"},on:{"complete":_vm.completeRecord},scopedSlots:_vm._u([{key:"default",fn:function({ tableData }){return [_c('el-table',{attrs:{"data":tableData.list,"max-height:200":"","header-cell-style":{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }},on:{"sort-change":_vm.sortChange}},[(_vm.type != 1)?_c('el-table-column',{attrs:{"prop":"file_name","label":"订单号","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"TitleVideo"},[_vm._v(" "+_vm._s(scope.row.order_number)+" ")])]}}],null,true)}):_vm._e(),_c('el-table-column',{attrs:{"label":"付款学员 ","min-width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"buyerBox"},[_c('img',{attrs:{"src":scope.row.uphoto,"alt":""}}),_c('p',{attrs:{"title":scope.row.uname +
                            (scope.row.student_remarks
                              ? '(' + scope.row.student_remarks + ')'
                              : '')}},[_vm._v(" "+_vm._s(scope.row.uname + (scope.row.student_remarks ? '(' + scope.row.student_remarks + ')' : ''))+" ")])])]}}],null,true)}),(_vm.type != 3)?_c('el-table-column',{attrs:{"prop":"dataName","label":"资料名称 "},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.ed_name)+" ")]}}],null,true)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"buyPrice","label":"售价(元)","min-width":"55px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(" "+_vm._s(scope.row.selling_price == 0 ? '免费' : scope.row.selling_price)+" ")])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"promoterPrice","label":"实付(元)","min-width":"65px","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(scope.row.selling_price))])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"buyTime","label":"下单时间","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("formatTimeStamp")(scope.row.order_time,'yyyy-MM-dd hh:mm'))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"bfb","label":"状态","min-width":"55px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',{class:{ red: scope.row.status == 1 },staticStyle:{"text-align":"left","color":"#46932c"}},[_vm._v(" "+_vm._s(scope.row.status == 1 ? '未支付' : '支付成功')+" ")])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right","header-align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"btns1 cp"},[_c('p',{staticStyle:{"text-align":"right","color":"#0aa29b"},on:{"click":function($event){return _vm.seeOrder(scope.row.edo_id)}}},[_vm._v(" 详情 ")])])]}}],null,true)})],1)]}}])})],1)])])],_c('router-view')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }