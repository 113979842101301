<template>
  <div id="courseProgress">
    <div class="tip">提示：目前仅支持看录播的课程进度</div>
    <div class="listcontain">
      <pagination2 :option="post" url="/pcUser/rateList" ref="childDialogBox">
        <template v-slot:default="{ tableData }">
          <el-table
            ref="dialogTable"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column label="课程名称" min-width="190">
              <template slot-scope="{ row }">
                <div class="course-info">
                  <div class="cover">
                    <lazy class="img" :src="row.photo"></lazy>
                  </div>
                  <div class="right">
                    <div class="title">{{ row.name }}</div>
                    <div class="price">
                      {{ row.course_type | gettype }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="已学章节" min-width="90px">
              <template slot-scope="{ row }">
                <el-progress
                  :key="row.course_id"
                  :percentage="(row.learn_num / row.chapter_num) * 100"
                  :format="() => format(row)"
                  style="width: 200px"
                ></el-progress>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="right" min-width="100px">
              <template slot-scope="{ row }">
                <el-button type="text" @click="detailRecord(row)">
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </div>
    <el-drawer title="详情" size="558px" :visible.sync="drawer">
      <div slot="title" class="title">详情</div>
      <div class="drawer_contain" v-if="drawer">
        <div class="student_info">
          <div class="student">
            <div class="tu">
              <img
                style="width: 100%; height: 100%"
                :src="selected.photo"
                alt=""
              />
            </div>
            <div class="name" :title="selected.name">
              {{ selected.name }}
            </div>
          </div>
        </div>
        <div class="list_contain">
          <pagination2
            :option="post"
            url="/pcUser/rate"
            ref="childDialogBox"
            @complete="complete"
            :allFn="true"
          >
            <template v-slot:default="{ tableData }">
              <el-table
                ref="dialogTable"
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
                :data="tableData"
                style="width: 100%"
              >
                <el-table-column label="章节" min-width="100">
                  <template slot-scope="{ row }">
                    <div class="membership">
                      <div class="right" :title="row.name">
                        {{ row.name }}
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="类型">
                  <template slot-scope="{ row }">
                    {{ row.type == 1 ? '视频' : '音频' }}
                  </template>
                </el-table-column>

                <el-table-column label="进度">
                  <template slot-scope="{ row }">
                    <el-progress
                      :key="row.course_id"
                      :percentage="Number(row.rate)"
                      style="width: 150px"
                    ></el-progress>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </pagination2>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: 'courseProgress',
  data() {
    return {
      selected: {},
      drawer: false,
      post: {
        uid: this.uid,
        course_id: '',
      },
    }
  },
  props: {
    uid: {
      type: Number,
    },
  },
  methods: {
    // format2(val) {
    //   return `${val}%`
    // },
    detailRecord(row) {
      this.selected = row
      this.post.course_id = row.course_id
      this.$nextTick(() => {
        this.drawer = true
      })
    },
    format(val) {
      return `${val.learn_num}/${val.chapter_num}`
    },
  },

  filters: {
    gettype(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        default:
          text = '小班课'
      }
      return text
    },
  },
}
</script>

<style lang="scss" scoped>
#courseProgress {
  ::v-deep .el-drawer__header {
    margin-bottom: 0;
  }
  ::-webkit-scrollbar {
    width: 2px;
    height: 8px;
    background-color: #fff;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    background-color: #fff;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
  }
  ::v-deep .el-drawer__body {
    overflow-y: auto;
  }
  ::v-deep .el-progress-bar__outer {
    height: 12px !important;
    border-radius: 0 !important;
    .el-progress-bar__inner {
      border-radius: 0;
    }
  }
  .title {
    font-size: 14px;
    color: #333333;
  }
  .drawer_contain {
    .list_contain {
      margin: 0 20px;
      .membership {
        .right {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
        }
      }
    }
    .student_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px;
      box-sizing: border-box;
      padding: 10px;
      height: 49px;
      background: #f5f5f5;
      border-radius: 1px;
      .student {
        display: flex;
        align-items: center;
        .tu {
          width: 50px;
          height: 30px;
          border: 5px;
          background: #d8d8d8;
          margin-right: 10px;
        }
        .name {
          width: 410px;
          font-size: 14px;
          color: #333333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
      .btns {
        .sp_driver {
          color: rgba(27, 157, 151, 1);
          margin: 0 5px;
          font-size: 16px;
        }
      }
    }
  }
  .listcontain {
    .course-info {
      display: flex;
      .cover {
        width: 68px;
        height: 38px;
        position: relative;
        .img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        width: calc(100% - 76px);
        margin-left: 8px;
        .title {
          font-size: 13px;
          font-weight: 500;
          line-height: 17px;
          color: rgba(51, 51, 51, 1);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .price {
          .p1 {
            font-size: 12px;
            font-weight: 600;
            line-height: 17px;
            margin-right: 15px;
            color: rgba(255, 53, 53, 1);
            i {
              margin-left: 20px;
              color: #ccc;
            }
            .costprice {
              margin-left: 10px;
              color: #999;
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }
  .tip {
    margin: 20px 0;
    font-size: 12px;
    color: #ff3535;
  }
}
</style>
