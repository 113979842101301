<template>
  <div>
    <pagination2 ref="child" :option="post2" url="/pcUser/learnRecord">
      <template v-slot:default="{ tableData }">
        <el-table
          @sort-change="sortchange"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333'
          }"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            label="进入页面时间"
            prop="c_time"
            width="130px"
            sortable="custom"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.c_time != 0">
                {{ scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column
            label="在线时长"
            prop="visit_duration"
            width="130px"
            sortable="custom"
          >
            <template slot-scope="scope">
                {{ scope.row.visit_duration | formatTimeLength }}
            </template>
          </el-table-column>
          <el-table-column
            label="学习时间"
            prop="learning_time"
            width="130px"
            sortable="custom"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.learning_time != 0">
                {{
                  scope.row.learning_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                }}
              </div>
              <div v-else>--</div>
            </template>
            v
          </el-table-column>
          <el-table-column
            label="学习时长"
            prop="learning_duration"
            sortable="custom"
            width="190"
          >
            <template slot-scope="scope">
                {{ scope.row.learning_duration | formatTimeLength }}
            </template>
          </el-table-column>
          <el-table-column label="课程" prop="course_name" v-if="!isShow"/>
          <el-table-column label="章节" prop="name"  v-if="!isShow"/>
          <el-table-column label="直播名称" prop="name" v-if="isShow"/>
          <el-table-column label="直播类型" prop="live_type" v-if="isShow">
            <template slot-scope="scope">
                <!-- 类型 1公开课 2智能直播 -->
                <div v-if="scope.row.live_type == 1">公开课</div>
                <div v-if="scope.row.live_type == 2">智能直播</div>
            </template>
          </el-table-column>
          <el-table-column label="学习终端系统" width="130px">
            <template slot-scope="scope">
              <div v-if="type == 1 || type == 4 || type == 5||isShow">
                <!-- 1 H5 2 pc浏览器 3 小程序序 -->
                <div v-if="scope.row.user_from == 1">微信H5</div>
                <div v-if="scope.row.user_from == 2">小程序</div>
                <div v-if="scope.row.user_from == 3">电脑</div>
              </div>
              <div v-if="type == 2 || type == 3">
                <!-- 1 H5 2 pc浏览器 3 小程序序 -->
                <div v-if="scope.row.user_from == 2">小程序</div>
                <div v-if="scope.row.user_from == 1">电脑</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="地理位置" prop="play_times">
            <template slot-scope="scope">
              <span>{{ scope.row.province }} {{ scope.row.city }}</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: Number,
      default: 0
    },
    user_from: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      post2: {
        uid: '',
        type: this.type,
        user_from: this.user_from,
        orderType: 1,
        sortType: 2
      },
      allNum: 0,
      datalist: [], //录播
      totals: 0, // 总数量
      nowPage: 1 //当前页数
    }
  },

  watch: {
    type: {
      handler() {
        this.post2.type = this.type
      },
      immediate: true,
      deep: true
    },
    user_from() {
      this.post2.user_from = this.user_from
    }
  },
  computed:{
    isShow(){
      return this.type==6||this.type==7
    }
  },
  methods: {
    sortchange({ prop, order }) {
      if (prop === 'c_time') {
        this.post2.orderType = 1
        this.$refs.child.reset()
      } else if (prop === 'visit_duration') {
        this.post2.orderType = 2
        this.$refs.child.reset()
      } else if (prop == 'learning_time') {
        this.post2.orderType = 3
        this.$refs.child.reset()
      } else if (prop == 'learning_duration') {
        this.post2.orderType = 4
        this.$refs.child.reset()
      }
      if (order == 'ascending') {
        this.post2.sortType = 1
      } else {
        this.post2.sortType = 2
      }
    },
    handleCurrentChange(val) {
      //点击切换分页
      const that = this
      that.nowPage = val
      that.getDate()
    },
    getDate() {
      const that = this
      this.$http({
        url: '/User/videoPlayRecord',
        data: {
          uid: this.uid,
          page: this.nowPage
        },
        callback: ({ data, code, allNum }) => {
          if (code == 200) {
            that.datalist = data
            that.allNum = Number(allNum)
          }
        },
        error: error => {
          if (error.code == 204) {
            that.datalist = []
          }
        }
      })
    }
  },
  created() {
    this.post2.uid = this.$route.query.uid
  }
}
</script>

<style lang="less" scoped>
.el-button--text {
  color: #1b9d97;
}
.name-dt {
  float: left;
  margin-right: 10px;
}
.name-dt img {
  width: 34px;
  height: 34px;
  border-radius: 17px;
}
.name-dd-txt {
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
  line-height: 16px;
  padding-left: 44px;
}
.name-dd {
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
  padding-left: 44px;
}
/deep/ .el-button--primary {
  border-radius: 4px !important;
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
/deep/ .el-button.el-button--default:hover {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}
/deep/ .el-dialog__body {
  padding: 0px;
}
/deep/ .el-tag.el-tag--success {
  width: 68px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid rgba(10, 162, 155, 1);
  color: rgba(10, 162, 155, 1);
  background: #fff;
}
/deep/ .el-tag.el-tag--info.el-tag--plain {
  margin: 5px;
}
/deep/ .el-tag.el-tag--warning.el-tag--plain {
  text-align: center;
  border: 1px solid rgba(10, 162, 155, 1);
  margin: 5px;
  background: rgba(10, 162, 155, 1);
  border-radius: 4px;
  color: white;
}
/deep/ .el-dialog.add-tag {
  height: 350px;
}
.head-left {
  float: left;
}
.head-right {
  height: 40px;
  float: right;
  margin-top: 10px;
}
.head-left .el-button {
  width: 126px !important;
  height: 42px !important;
  border-radius: 4px !important;
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
.head-left .el-button:hover {
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
/deep/ .set-popover {
  height: 340px !important;
}
/deep/ .tag-button {
  white-space: normal;
  font-size: 14px;
  color: rgba(10, 162, 155, 1);
  line-height: 19px;
  text-align: left;
}
</style>
