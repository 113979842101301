var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"opencassOrder"}},[[_c('div',{staticClass:"header2"},[_c('div',{staticClass:"left"},[_c('span',[_vm._v("累计订单收入(元)："+_vm._s(_vm.find.price))]),_c('span',{staticClass:"ml50"},[_vm._v("累计订单(笔)："+_vm._s(_vm.find.all_count))])])]),_c('pagination2',{ref:"child",attrs:{"option":_vm.post,"url":_vm.type == 1 ? '/LiveGoods/orderList' : '/LiveGoods/goodsOrderList'},on:{"complete":_vm.complete},scopedSlots:_vm._u([{key:"default",fn:function({ tableData: { list } }){return [_c('el-table',{attrs:{"data":list,"header-cell-style":{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }}},[_c('el-table-column',{attrs:{"label":"订单号","prop":"number"}}),_c('el-table-column',{attrs:{"label":"付款学员","min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticClass:"paymember"},[_c('div',{staticClass:"member_photo"},[_c('img',{attrs:{"src":row.uphoto,"alt":""}})]),_c('div',{staticClass:"font1",attrs:{"title":`${row.uname}${
                      !row.student_remarks
                        ? ''
                        : '(' + row.student_remarks + ')'
                    }`}},[_vm._v(" "+_vm._s(row.uname)+" "),(row.student_remarks)?_c('span',[_vm._v(" ("+_vm._s(row.student_remarks)+") ")]):_vm._e()])])]}}],null,true)}),(_vm.type == 2)?_c('el-table-column',{attrs:{"label":"商品","prop":"goods_name"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticClass:"tu_name"},[_c('div',{staticClass:"tu"},[_c('img',{attrs:{"src":row.goods_cover_img,"alt":""}})]),_c('div',{staticClass:"name",attrs:{"title":row.goods_name}},[_vm._v(" "+_vm._s(row.goods_name)+" ")])])]}}],null,true)}):_c('el-table-column',{attrs:{"label":"代金券","prop":"goods_name"}}),_c('el-table-column',{attrs:{"label":"售价(元)"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" ￥"+_vm._s(row.selling_price)+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"实付(元)","prop":"price"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v("￥"+_vm._s(row.price))]}}],null,true)}),_c('el-table-column',{attrs:{"label":"付款时间","prop":"order_time"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("formatTimeStamp")(row.order_time,'yyyy-MM-dd hh:mm'))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"状态","prop":"costprice"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.status == 1)?_c('span',{staticStyle:{"color":"#ff3535"}},[_vm._v(" 未支付 ")]):_c('span',{staticStyle:{"color":"#46932c"}},[_vm._v("支付成功")])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticClass:"btns"},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$router.push({
                      path:
                        '/capitalOrder/cashcouponDetail/' +
                        row.live_goods_order_id,
                    })}}},[_vm._v(" 详情 ")])],1)]}}],null,true)})],1)]}}])})],_c('router-view')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }