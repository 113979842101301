var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"saleOrder"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"search"},[_c('el-form',{ref:"ruleIngredientsForm",staticClass:"flex-center",attrs:{"label-width":"70px","model":_vm.search,"size":"small"}},[_c('el-form-item',{staticClass:"mr20",attrs:{"label":"关键词"}},[_c('div',{staticClass:"search-item search-item1"},[_c('div',{staticClass:"vm select"},[_c('el-select',{attrs:{"size":"medium"},model:{value:(_vm.search.search_type),callback:function ($$v) {_vm.$set(_vm.search, "search_type", $$v)},expression:"search.search_type"}},_vm._l((_vm.options1),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"vm input"},[_c('el-input',{attrs:{"placeholder":_vm.placeholder,"size":"medium","clearable":""},model:{value:(_vm.search.search),callback:function ($$v) {_vm.$set(_vm.search, "search", $$v)},expression:"search.search"}})],1)])]),_c('el-form-item',{staticClass:"mr20",attrs:{"label":"付款时间"}},[_c('div',{staticClass:"vm select"},[_c('el-date-picker',{staticStyle:{"width":"360px"},attrs:{"type":"datetimerange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","unlink-panels":"","value-format":"timestamp","default-time":['00:00:00', '23:59:59'],"size":"medium"},on:{"change":function($event){return _vm.selectedTime(arguments[0], [
                  'start_order_time',
                  'end_order_time',
                ])}},model:{value:(_vm.search.order_time),callback:function ($$v) {_vm.$set(_vm.search, "order_time", $$v)},expression:"search.order_time"}})],1)]),_c('el-button',{staticStyle:{"margin-bottom":"18px"},attrs:{"size":"medium","type":"primary"},on:{"click":_vm.toSearch}},[_vm._v(" 搜索 ")])],1)],1)]),_c('pagination2',{ref:"childDialogBox",staticClass:"childDialogBox",attrs:{"option":_vm.search2,"url":"/Graphic/buyOrder"},scopedSlots:_vm._u([{key:"default",fn:function({ tableData: { list } }){return [_c('el-table',{attrs:{"header-cell-style":{
          background: 'rgba(245,245,245,1)',
          color: '#333333',
        },"data":list}},[_c('el-table-column',{attrs:{"label":"订单号","prop":"number"}}),_c('el-table-column',{attrs:{"label":"用户","min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticClass:"user_contain"},[_c('div',{staticClass:"tu"},[_c('img',{attrs:{"src":row.uphoto,"alt":""}})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(row.uname))])])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"手机号"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(_vm._s(row.umobile || '-'))]}}],null,true)}),_c('el-table-column',{attrs:{"label":"售价"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(row.selling_price)+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"实付"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(row.price)+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"付款时间","width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("formatTimeStamp")(row.order_time))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(row.status == 1 ? '未支付 ' : '支付成功')+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right","min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$router.push({
                  path: '/capitalOrder/graDetails',
                  query: { id: row.g_order_id },
                })}}},[_vm._v(" 详情 ")])]}}],null,true)})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }