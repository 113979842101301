<template>
  <div class="visitoRecord">
    <template>
      <pagination2 :option="post1" url="/pcUser/visitHistory">
        <template
          v-slot:default="{
            tableData: {
              today_visit,
              visit,
              today_course_visit,
              course_visit,
              today_live_visit,
              live_visit,
              list
            }
          }"
        >
          <template>
            <div class="top1">
              <el-row>
                <el-col :span="4">
                  <div class="flex">
                    <div>今日访问(次)</div>
                    <helpIcon
                      style="margin-left:6px;"
                      prompt="该学员今日访问网校、我的课程、课程详情、个人中心、直播间、公开课、智能直播、分享课程海报页的总次数"
                    ></helpIcon>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="flex">
                    <div>累计访问(次)</div>
                    <helpIcon
                      style="margin-left:6px;"
                      prompt="该学员累计访问网校、我的课程、课程详情、个人中心、直播间、公开课、智能直播、分享课程海报页的总次数"
                    ></helpIcon>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="flex">
                    <div>今日课程访问时长</div>
                    <helpIcon
                      style="margin-left:6px;"
                      prompt="该学员今日访问课程详情、直播间、分享课程海报页的总时长"
                    ></helpIcon>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="flex">
                    <div>累计课程访问时长</div>
                    <helpIcon
                      style="margin-left:6px;"
                      prompt="该学员累计访问课程详情、直播间、分享课程海报页的总时长"
                    ></helpIcon>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="flex">
                    <div>今日直播访问时长</div>
                    <helpIcon
                      style="margin-left:6px;"
                      prompt="该学员今日访问公开课、智能直播页的总时长"
                    ></helpIcon>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="flex">
                    <div>累计直播访问时长</div>
                    <helpIcon
                      style="margin-left:6px;"
                      prompt="该学员累计访问公开课、智能直播页的总时长"
                    ></helpIcon>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <!-- getVideoDuration -->
                <el-col :span="4">{{ today_visit || 0 }}</el-col>
                <el-col :span="4">{{ visit || 0 }}</el-col>
                <el-col :span="4">
                  {{ today_course_visit | formatTimeLength }}
                </el-col>
                <el-col :span="4">{{ course_visit | formatTimeLength }}</el-col>
                <el-col :span="4">
                  {{ today_live_visit | formatTimeLength }}
                </el-col>
                <el-col :span="4">{{ live_visit | formatTimeLength }}</el-col>
              </el-row>
            </div>
            <div style="margin:20px;margin-left:0">
              <el-tabs
                v-model="activeName"
                type="card"
                @tab-click="handleClick"
              >
                <el-tab-pane label="课程访问记录" name="1"></el-tab-pane>
                <el-tab-pane label="网校访问记录" name="2"></el-tab-pane>
                <el-tab-pane label="登录记录" name="3"></el-tab-pane>
                <el-tab-pane label="直播访问记录" name="4"></el-tab-pane>
              </el-tabs>
            </div>
          </template>
          <el-table
            v-if="activeName == '1'"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333'
            }"
            :data="list"
            style="width: 100%"
          >
            <el-table-column
              prop="c_time"
              label="访问时间"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                {{ scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>
            <el-table-column prop="name" label="访问课程"></el-table-column>
            <el-table-column label="停留时长">
              <template slot="header">
                <div class="flex-center">
                  停留时长
                  <helpIcon
                    style="margin-left:6px;"
                    prompt="该学员访问课程详情、直播间、分享课程海报页的时长"
                  ></helpIcon>
                </div>
              </template>
              <template slot-scope="scope">
                {{ scope.row.duration | formatTimeLength }}
              </template>
            </el-table-column>
            <el-table-column label="访问来源">
              <template slot-scope="scope">
                <!-- 1 h5 2 小程序 3 PC浏览器 -->
                <span v-if="scope.row.user_from == 1">微信H5</span>
                <span v-if="scope.row.user_from == 2">小程序</span>
                <span v-if="scope.row.user_from == 3">电脑</span>
              </template>
            </el-table-column>
          </el-table>

          <el-table
            v-if="activeName == '2'"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333'
            }"
            :data="list"
            style="width: 100%"
          >
            <el-table-column
              prop="c_time"
              label="访问时间"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                {{ scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>
            <el-table-column prop="today_visit_user_num">
              <template slot="header">
                <div class="flex-center">
                  停留时长
                  <helpIcon
                    style="margin-left:6px;"
                    prompt="该学员访问网校、我的课程、课程详情、个人中心、直播间、分享课程海报页的时长"
                  ></helpIcon>
                </div>
              </template>
              <template slot-scope="scope">
                {{ scope.row.duration | formatTimeLength }}
              </template>
            </el-table-column>
            <el-table-column label="访问来源">
              <template slot-scope="scope">
                <!-- 1 h5 2 小程序 3 PC浏览器 -->
                <span v-if="scope.row.user_from == 1">微信H5</span>
                <span v-if="scope.row.user_from == 2">小程序</span>
                <span v-if="scope.row.user_from == 3">电脑</span>
              </template>
            </el-table-column>
          </el-table>

          <el-table
            v-if="activeName == '3'"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333'
            }"
            :data="list"
            style="width: 100%"
          >
            <el-table-column label="登录时间" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                {{ scope.row.login_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>
            <el-table-column prop="user_ip" label="IP地址"></el-table-column>
            <el-table-column prop="video_num" label="参考地点">
              <template slot-scope="scope">
                <span>{{ scope.row.province }} {{ scope.row.city }}</span>
              </template>
            </el-table-column>
            <el-table-column label="登录来源">
              <template slot-scope="scope">
                <!-- 1 h5 2 小程序 3 PC浏览器 -->
                <span v-if="scope.row.login_from == 1">微信H5</span>
                <span v-if="scope.row.login_from == 2">小程序</span>
                <span v-if="scope.row.login_from == 3">电脑</span>
              </template>
            </el-table-column>
          </el-table>

          <el-table
            v-if="activeName == '4'"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333'
            }"
            :data="list"
            style="width: 100%"
          >
            <el-table-column
              prop="c_time"
              label="访问时间"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                {{ scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>
            <el-table-column prop="name" label="访问直播"></el-table-column>
            <el-table-column label="停留时长">
              <template slot-scope="scope">
                {{ scope.row.duration | formatTimeLength }}
              </template>
            </el-table-column>
            <el-table-column label="直播类型">
              <template slot-scope="scope">
                <!-- 类型 1公开课 2智能直播 -->
                <span v-if="scope.row.live_type == 1">公开课</span>
                <span v-if="scope.row.live_type == 2">智能直播</span>
              </template>
            </el-table-column>
            <el-table-column label="访问来源">
              <template slot-scope="scope">
                <!-- 1 h5 2 小程序 3 PC浏览器 -->
                <span v-if="scope.row.user_from == 1">微信H5</span>
                <span v-if="scope.row.user_from == 2">小程序</span>
                <span v-if="scope.row.user_from == 3">电脑</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </template>
  </div>
</template>

<script>
import helpIcon from '@/components/helpIcon'
export default {
  name: 'visitoRecord',
  components: { helpIcon },

  data() {
    return {
      post1: {
        type: 1,
        uid: ''
      },
      activeName: '1'
    }
  },
  created() {
    this.post1.uid = this.$route.query.uid
  },
  methods: {
    handleClick(val) {
      this.post1.type = val.name
    }
  }
}
</script>

<style lang="less" scoped>
.visitoRecord {
  .top1 {
    height: 67px;
    background-color: #fff;
    margin-top: 30px;
    box-sizing: border-box;
    overflow: hidden;
    ::v-deep .el-row {
      .el-col {
        .flex {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 13px;
          color: rgba(102, 102, 102, 1);
          line-height: 17px;
        }
        .number {
          font-size: 22px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          line-height: 29px;
          margin-top: 9px;
        }
      }
    }
    .el-row:first-child {
      font-size: 13px;
      color: rgba(102, 102, 102, 1);
      line-height: 17px;
    }
    .el-row:last-child {
      margin-top: 10px;
      font-size: 22px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 29px;
    }
  }
  /deep/ .el-tabs__header {
    .el-tabs__item {
      width: 131px;
      text-align: center;
      font-size: 14px;
    }
  }
}
</style>
