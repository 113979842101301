import { render, staticRenderFns } from "./visitoRecord.vue?vue&type=template&id=85a2c786&scoped=true&"
import script from "./visitoRecord.vue?vue&type=script&lang=js&"
export * from "./visitoRecord.vue?vue&type=script&lang=js&"
import style0 from "./visitoRecord.vue?vue&type=style&index=0&id=85a2c786&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85a2c786",
  null
  
)

export default component.exports