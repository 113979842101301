<!--
 * @Author: cyy
 * @Date: 2021-12-02 18:35:49
 * @LastEditTime: 2022-08-01 17:14:18
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\teachingAdmin\detail\coursePurchas\OrderComponents\data.vue
-->
<template>
  <div class="promotionRecord">
    <template>
      <div class="headerMsg">
        <span>累计订单收入(元)：{{ headerInfo.price }}</span>
        <span>累计订单(笔)：{{ headerInfo.all_count }}</span>
      </div>
      <div class="contentVideo">
        <!-- 左侧菜单 -->
        <div class="rightMenu">
          <!-- 右侧内容视频 列表- 分页 -->
          <div class="rightCon">
            <pagination2
              :option="postRecord"
              url="/electronicsData/order"
              ref="childBackRecord"
              @complete="completeRecord"
            >
              <template v-slot:default="{ tableData }">
                <el-table
                  :data="tableData.list"
                  @sort-change="sortChange"
                  max-height:200
                  :header-cell-style="{
                    background: 'rgba(245,245,245,1)',
                    color: '#333333',
                  }"
                >
                  <el-table-column
                    prop="file_name"
                    label="订单号"
                    min-width="120"
                    v-if="type != 1"
                  >
                    <template slot-scope="scope">
                      <div class="TitleVideo">
                        {{ scope.row.order_number }}
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="付款学员 " min-width="80px">
                    <template slot-scope="scope">
                      <div class="buyerBox">
                        <img :src="scope.row.uphoto" alt="" />
                        <p
                          :title="
                            scope.row.uname +
                              (scope.row.student_remarks
                                ? '(' + scope.row.student_remarks + ')'
                                : '')
                          "
                        >
                          {{
                            scope.row.uname +
                              (scope.row.student_remarks
                                ? '(' + scope.row.student_remarks + ')'
                                : '')
                          }}
                        </p>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="dataName"
                    v-if="type != 3"
                    label="资料名称 "
                  >
                    <template slot-scope="scope">
                      {{ scope.row.ed_name }}
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="buyPrice"
                    label="售价(元)"
                    min-width="55px"
                  >
                    <template slot-scope="scope">
                      <p>
                        {{
                          scope.row.selling_price == 0
                            ? '免费'
                            : scope.row.selling_price
                        }}
                      </p>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="promoterPrice"
                    label="实付(元)"
                    min-width="65px"
                    sortable="custom"
                  >
                    <template slot-scope="scope">
                      <p>{{ scope.row.selling_price }}</p>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="buyTime"
                    label="下单时间"
                    sortable="custom"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.order_time
                          | formatTimeStamp('yyyy-MM-dd hh:mm')
                      }}
                    </template>
                  </el-table-column>

                  <el-table-column prop="bfb" label="状态" min-width="55px">
                    <template slot-scope="scope">
                      <p
                        style="text-align: left; color: #46932c"
                        :class="{ red: scope.row.status == 1 }"
                      >
                        {{ scope.row.status == 1 ? '未支付' : '支付成功' }}
                      </p>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="操作"
                    fixed="right"
                    header-align="right"
                  >
                    <template slot-scope="scope">
                      <div class="btns1 cp">
                        <p
                          style="text-align: right; color: #0aa29b"
                          @click="seeOrder(scope.row.edo_id)"
                        >
                          详情
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </pagination2>
            <!--分页-->
          </div>
        </div>
      </div>
    </template>
    <router-view />
  </div>
</template>

<script>
import dataBase from '@/mixin/dataBase'

export default {
  name: 'promotionRecord',

  mixins: [dataBase],

  props: {
    type: {
      type: Number, //默认是1在全部订单里
      default: 1,
    },
    uid: [String, Number],
  },

  data() {
    return {
      tableData: [],
      headerInfo: {},
      postRecord: {
        uid: this.uid,
        type: '1',
        search: '',
        order: '',
        sort: '',
        price_type: '0',
        status: '0',
        startTime: '',
        endTime: '',
        courseTime: '',
      },
    }
  },

  methods: {
    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.postRecord[contextName[index]] = item / 1000

        // 结束时间加到这天的最后一秒
        if (index == 1) {
          this.postRecord[contextName[index]] += 86399
        }
      })
    },
    // 网校的一些基本数据
    headerData(value) {
      return [
        {
          top: '累计收入(元)',
          bottom: value.price || '0.00',
          name: Math.random(),
        },
      ]
    },
    getData() {},
    seeOrder(id) {
      this.$router.push({
        path: `/capitalOrder/orderData/` + id,
      })
    },
    completeRecord(val) {
      this.headerInfo = val.data.find
    },
    trimStr(str) {
      return str.replace(/(^\s*)|(\s*$)/g, '')
    },
    // 排序
    sortChange(value) {
      var order = 0
      var sort = 0
      switch (value.order) {
        case null:
          break
        case 'descending':
          sort = 2
          break
        case 'ascending':
          sort = 1
          break
      }
      switch (value.prop) {
        case null:
          break
        case 'promoterPrice':
          order = 1
          break
        case 'buyTime':
          order = 2
          break
      }
      this.postRecord.order = order
      this.postRecord.sort = sort
    },
  },
}
</script>
<style lang="scss" scoped>
.red {
  color: rgb(255, 53, 53) !important;
}

.redYJS {
  color: red;
}

.headerMsg {
  margin-bottom: 20px;

  span {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #666666;
    margin-right: 20px;
  }
}

.ppp {
  width: 90px;
  text-align: left;
}

.zJName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assistant-ps {
  position: fixed;
  top: 18px;
  left: 253px;
  z-index: 9;
}

.el-dropdown-menu__item {
  width: 80px;
}

.isencryptionVideoTypeBg {
  background: #f0f8ff;
}

.encryptionVideoContent {
  width: 100%;
  height: 60%;

  span {
    height: 12px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #ff3535;
    line-height: 12px;
  }

  div {
    width: 90%;
    height: 120px;
    margin: 0 auto;
    margin-top: 40px;
    position: relative;

    p {
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 60px;
      position: relative;

      i {
        position: absolute;
        top: 21px;
        right: 0;
      }
    }
  }
}

.el-dialog__body {
  .el-select {
    width: 100%;
  }
}

.isbg {
  background: #f0f8ff;
}

.footer {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-bottom: 10px;
  margin-left: 62px;

  .el-button {
    width: 136px;
    margin-top: 20px;
  }
}

.buyerBox {
  display: flex;
  justify-content: flex-start;
  height: 100%;

  img {
    width: 26px;
    height: 26px;
    border-radius: 13px;
  }

  p {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 14px;
    margin-top: 7px;
    margin-left: 10px;
  }
}

.contentVideo {
  position: relative;
  display: flex;
  height: 100%;

  .fff {
    width: 250px;
    height: 45px;
    visibility: hidden;
  }

  .leftMenu {
    width: 250px;
    background: #ffffff;
    border: 1px solid #e4e4e4;
    position: relative;
    min-height: 400px;
    height: calc(100% - 45px);

    .boxMenu {
      margin-top: 36px;
      width: 250px;

      .childMenu {
        width: 250px;
        height: 36px;
        cursor: pointer;

        img {
          width: 15px;
          height: 14px;
          position: relative;
          top: 12px;
          left: 20px;
        }

        p {
          height: 12px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          // color: #0AA29B;
          position: relative;
          left: 40px;
          bottom: 1px;
        }

        .hoverDDD {
          display: none;
          width: 25px;
          height: 26px;
          margin-left: 210px;
          margin-top: -16px;
          position: relative;
        }

        .childchildMenu {
          position: absolute;
          z-index: 999;
          width: 100px;
          height: 88px;
          right: 0;
          box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          border: 1px solid #f7f7f7;
          display: none;

          div:hover {
            background-color: #f0f8ff;
          }

          div {
            width: 100px;
            height: 44px;
            background: #fff;
            text-align: center;

            span {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #4a4a4a;
              line-height: 44px;
            }
          }
        }
      }
    }

    .footAddMenu {
      position: absolute;
      top: 0;
      width: 250px;
      height: 36px;
      text-align: center;
      border-bottom: 1px solid #e4e4e4;
      cursor: pointer;

      img {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 11px;
        left: 82px;
      }

      p {
        height: 13px;
        font-size: 13px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 36px;
      }
    }
  }

  .rightMenu {
    flex: 1;
    position: relative;
    overflow-x: hidden;
  }
}

.promotionRecord {
  background: #fff;
}

::v-deep .promotionRecord .el-table {
  padding: 0 20px;
}

.btns {
  @extend %btns;
  position: relative;

  p {
    height: 14px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #1b9d97;
    line-height: 14px;
    cursor: pointer;
  }
}

/**搜索**/
.search-contain {
  font-size: 0;
  text-align: right;
}

.search-item {
  margin-bottom: 30px;

  .middle-space {
    font-size: 14px;
    line-height: 19px;
    margin: 0 10px;
    color: rgba(51, 51, 51, 1);
  }

  ::v-deep .el-input,
  ::v-deep .select {
    height: 36px;

    .el-input__inner {
      height: 36px;
    }

    .el-input__icon {
      line-height: 36px;
    }

    .el-date-editor .el-range__icon {
      line-height: 31px;
      display: none;
    }

    .el-range-separator {
      line-height: 29px;
    }
  }
}

::v-deep .search-item9 .select,
::v-deep .search-item9 .el-input {
  height: auto;
  min-height: 36px;
}

::v-deep .search-item1 {
  .el-select {
    width: 120px;
  }
}

::v-deep .search-item2 {
  .el-input {
    width: 120px;
  }
}

::v-deep .search-item1,
::v-deep .search-item3,
::v-deep .search-item2 {
  .el-input__icon {
    line-height: 27px !important;
  }
}

::v-deep .search-item1 {
  .select {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .input {
    input {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
