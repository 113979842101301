<template>
  <div>
    <div class="top1">
      <div class="top2">
        <div class="aling1">
          <div class="tu1">
            <img :src="userlist.uphoto" alt="" />
            <div class="iconphoto">
              <img src="~@ass/img/ico_ghtx@2x.png" alt="" />
            </div>
            <changePhotoimg
              :uid="Number($route.query.uid)"
              url="/pcUser/editUserPhoto"
              @complete="completechangePhotoimg"
              class="changePhotoimg"
              :size="7"
            ></changePhotoimg>
          </div>

          <div class="student_name">
            <div class="zi1">{{ userlist.uname }}</div>
            <div class="zi2">
              注册时间：{{
                userlist.regdate | formatTimeStamp('yyyy-MM-dd hh:mm')
              }}
              <i style="margin: 0 10px">|</i>
              最近登录：{{ userlist.signTime }} （{{
                userlist.lastdate | formatTimeStamp('yyyy-MM-dd hh:mm')
              }}）
            </div>
          </div>
        </div>

        <!-- {{ showtag }} -->
        <div class="right">
          <el-row>
            <el-button
              style="width: 100px; color: red; height: 36px; line-height: 10px"
              v-if="userlist.ustatus == 1 && showBlackBtn"
              @click="showBlackConfirm = true"
            >
              拉黑
            </el-button>
            <el-button
              style="width: 100px; color: red; height: 36px; line-height: 10px"
              v-if="userlist.ustatus == 2"
              @click="moveblacklist"
            >
              取消拉黑
            </el-button>
            <el-button
              v-if="showEditBtn"
              type="primary"
              style="width: 100px"
              @click="editUserInfo"
            >
              编辑
            </el-button>
          </el-row>
        </div>

        <div style="clear: both"></div>
        <div class="bottom1">
          <el-row :gutter="20">
            <el-col :span="8">
              <div class="flex-center">
                <div class="zi3">真实姓名</div>
                <div class="zi4">{{ userlist.student_remarks || '无' }}</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="flex-center">
                <div class="zi3">账户绑定手机号</div>
                <div class="zi4">{{ userlist.umobile || '无' }}</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="flex-center">
                <div class="zi3">最近采集手机号</div>
                <div class="zi4">{{ userlist.cj_mobile || '无' }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <div class="flex-center">
                <div class="zi3">邮箱地址</div>
                <div class="zi4">{{ userlist.uemail || '无' }}</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="flex-center">
                <div class="zi3">性别</div>
                <div class="zi4">{{ userlist.usex || '无' }}</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="flex-center">
                <div class="zi3">微信号</div>
                <div class="zi4">{{ userlist.wx_number || '无' }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <div class="flex-center">
                <div class="zi3">地区</div>
                <div class="zi4">{{ userlist.area || '无' }}</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="flex-center">
                <div class="zi3">分组</div>
                <div class="zi4">
                  <el-select
                    style="width: 198px"
                    v-model="value"
                    @change="dogroup"
                    placeholder="暂无分组"
                  >
                    <el-option label="无" :value="0"></el-option>
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="flex-center">
                <div class="zi3">学历</div>
                <div class="zi4">{{ userlist.education || '无' }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <div class="flex-center">
                <div class="zi3">年龄</div>
                <div class="zi4">{{ userlist.age || '无' }}</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="flex-center">
                <div class="zi3">职业</div>
                <div class="zi4">{{ userlist.position || '无' }}</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="flex-center">
                <div class="zi3">机构(单位)</div>
                <div class="zi4">{{ userlist.company || '无' }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <div class="flex-center">
                <div class="zi3">注册来源</div>
                <div class="zi4">
                  {{
                    userlist.reg_source == 1
                      ? 'h5网校'
                      : userlist.reg_source == 2
                      ? '叮叮开课直播小程序'
                      : '推广招生-抖音广告'
                  }}
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="flex-center">
                <div class="zi3">描述</div>
                <div class="zi4 textHidden" :title="userlist.ujianjie">{{ userlist.ujianjie || '无' }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <div class="flex-center">
                <div class="zi3">标签</div>
                <tagselect :tag="userlist.tag" :uids="$route.query.uid" />
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 编辑弹窗 -->
        <el-dialog
          :visible.sync="dialogVisible"
          width="700px"
          class="dialogVisible"
          :close-on-click-modal="false"
        >
          <div slot="title" class="student">
            编辑学员
            <span class="redcolor fs12" style="margin-left: 15px">
              重要提示：编辑内容会同步到学员个人中心，请谨慎操作
            </span>
          </div>
          <el-form
            label-position="left"
            :model="edit"
            ref="editUserInfo"
            label-width="100px"
            :hide-required-asterisk="true"
            :rules="{
              uemail: [
                {
                  required: true,
                  validator: verifyEmail,
                },
              ],
            }"
          >
            <el-row>
              <el-col :span="4">真实姓名</el-col>
              <el-col :span="20">
                <el-input
                  type="text"
                  placeholder="无"
                  v-model="edit.student_remarks"
                  maxlength="30"
                  show-word-limit
                ></el-input>
              </el-col>
            </el-row>

            <el-form-item prop="uemail" label="邮箱地址">
              <el-input
                style="margin-left: 10px; width: 550px"
                placeholder="无"
                v-model="edit.uemail"
              ></el-input>
            </el-form-item>

            <el-row>
              <el-col :span="4">微信号</el-col>
              <el-col :span="20">
                <el-input
                  type="text"
                  placeholder="无"
                  maxlength="30"
                  v-model="edit.wx_number"
                  show-word-limit
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="4">地区</el-col>
              <el-col :span="20">
                <el-cascader
                  v-model="edit.area"
                  :options="city"
                  placeholder="无"
                ></el-cascader>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="4">学历</el-col>
              <el-col :span="20">
                <el-input
                  type="text"
                  placeholder="无"
                  v-model="edit.education"
                  maxlength="30"
                  show-word-limit
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="4">年龄</el-col>
              <el-col :span="20">
                <el-input
                  type="text"
                  maxlength="30"
                  placeholder="无"
                  v-model="edit.age"
                  show-word-limit
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="4">职业</el-col>
              <el-col :span="20">
                <el-input
                  type="text"
                  maxlength="30"
                  placeholder="无"
                  v-model="edit.position"
                  show-word-limit
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="4">机构(单位)</el-col>
              <el-col :span="20">
                <el-input
                  type="text"
                  maxlength="30"
                  placeholder="无"
                  v-model="edit.company"
                  show-word-limit
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="4">描述</el-col>
              <el-col :span="20">
                <el-input
                  type="text"
                  maxlength="140"
                  placeholder="无"
                  v-model="edit.ujianjie"
                  show-word-limit
                ></el-input>
              </el-col>
            </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button
              size="medium"
              style="width: 100px"
              @click="dialogVisible = false"
            >
              取消
            </el-button>
            <el-button
              size="medium"
              style="width: 100px"
              type="primary"
              @click="saveUserInfo"
            >
              保存
            </el-button>
          </span>
        </el-dialog>
      </div>
    </div>

    <template>
      <el-card class="box-card">
        <div class="item">
          <el-tabs v-model="activeName" @tab-click="changeTabs">
            <el-tab-pane label="学习统计" name="1">
              <learningstatistics v-if="activeName == '1'" />
            </el-tab-pane>
            <el-tab-pane label="学习记录" name="2">
              <learningrecords
                v-if="activeName == '2'"
                :uid="Number($route.query.uid)"
              />
            </el-tab-pane>
            <el-tab-pane label="报名记录" name="3">
              <purchaserecord
                v-if="activeName == '3'"
                :uname="userlist.uname"
              />
            </el-tab-pane>
            <el-tab-pane label="课程进度" name="7">
              <course-progress
                v-if="activeName == '7'"
                :uid="Number($route.query.uid)"
              />
            </el-tab-pane>
            <el-tab-pane label="评论记录" name="4">
              <commentRecord v-if="activeName == '4'" />
            </el-tab-pane>
            <el-tab-pane label="信息采集记录" name="5">
              <questionnaireRecord v-if="activeName == '5'" />
            </el-tab-pane>
            <el-tab-pane label="访问记录" name="6">
              <visitoRecord v-if="activeName == '6'" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-card>
      <!--拉黑-->
      <blackConfirm
        title="拉黑"
        v-if="showBlackConfirm"
        @close="showBlackConfirm = false"
        @complete="blacklist"
        placeholder="输入拉黑原因，选填"
        content="拉黑后学员无法进入网校、查看课程、报名课程，进入直播间，可随时取消拉黑！"
      ></blackConfirm>
    </template>
    <router-view />
  </div>
</template>

<script>
import changePhotoimg from '@cm/base/changePhotoimg'
import learningrecords from './detail/learning/learningrecords'
import purchaserecord from './detail/coursePurchas/purchaserecord'
import learningstatistics from './detail/learningstatistics'
import courseProgress from './detail/courseProgress'
import commentRecord from './detail/commentRecord'
import visitoRecord from './detail/visitoRecord'
import questionnaireRecord from './detail/questionnaireRecord'
import selectcities from '@/mixin/selectcities'
import blackConfirm from '@/components/messageBox'
// import VDistpicker from 'v-distpicker'
import tagselect from '@/components/tagselect/index.vue'
export default {
  mixins: [selectcities],
  components: {
    courseProgress,
    tagselect,
    purchaserecord,
    commentRecord,
    visitoRecord,
    questionnaireRecord,
    learningstatistics,
    learningrecords,
    blackConfirm,
    changePhotoimg,
  },

  data() {
    return {
      // 黑名单弹窗
      showBlackConfirm: false,

      options: [],

      value: '',

      dynamicValidateForm: {
        email: '',
      }, //编辑学员的列表

      status: '', //拉黑的状态

      value3: '',

      edit: {
        student_remarks: '',
        uemail: '',
        wx_number: '',
        area: [],
        school: '',
        industry: '',
        company: '',
        position: '',
      }, //编辑列表

      addInp: false,

      mask: false,

      dialogVisible: false, //编辑弹窗

      count: [],

      showtag: [],

      userlist: {}, //学员详情

      dateList: [], //观看记录列表

      activeName: '1', //默认全部

      type: 5, // 默认显示全部

      tabsData: [
        { label: '录播观看记录', name: 'first' },
        { label: '回放观看记录', name: 'second' },
        { label: '直播观看记录', name: 'third' },
      ],
    }
  },

  computed: {
    showBlackBtn() {
      return this.$store.state.roots.includes(109)
    },
    showEditBtn() {
      return this.$store.state.roots.includes(108)
    },
  },

  created() {
    if (this.$route.query.index) {
      this.activeName = String(this.$route.query.index)
    } else {
      this.activeName = '1'
    }
    this.status = this.$route.query.status
    this.getUserInfo1()
  },

  methods: {
    completechangePhotoimg() {
      this.getUserInfo1()
    },
    // 获取机构分组
    getGroupList() {
      this.$http({
        url: '/pcUser/getGroupList',
        callback: ({ data }) => {
          const o = []
          data.forEach((item) => {
            o.push({
              label: item.gname,
              value: Number(item.gid),
              count: item.number,
            })

            if (this.userlist.gid == item.gid) {
              this.value = Number(item.gid)
            }
          })
          this.options = o
        },
      })
    },

    //加入分组
    dogroup(val) {
      this.$http({
        url: '/pcUser/doGroup',
        data: {
          uids: [this.$route.query.uid],
          gid: val,
        },
        callback: () => {},
      })
    },

    //编辑提交表单的方法
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    verifyEmail(rule, value, callback) {
      if (
        !value ||
        /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value.trim())
      ) {
        callback()
      } else {
        callback(new Error('请输入正确的邮箱地址'))
      }
    },

    // 拉黑
    blacklist(blackout_reason) {
      this.$http({
        url: '/User/defriend',
        data: {
          uids: [this.$route.query.uid],
          blackout_reason,
        },
        callback: () => {
          this.$root.prompt({
            msg: '拉黑成功',
            type: 'success',
          })
          this.userlist.ustatus = 2
        },
      })
    },

    //取消拉黑
    moveblacklist() {
      this.$http({
        name: 'Defriend',
        url: '/User/befriend',
        data: {
          uids: Number(this.$route.query.uid),
        },
        callback: () => {
          this.$root.prompt({
            showClose: true,
            message: '取消拉黑成功',
            type: 'success',
          })
          this.userlist.ustatus = 1
        },
      })
    },

    getUserInfo1() {
      const that = this
      let promise = new Promise((resolve) => {
        that.$http({
          url: '/PcUser/getUserInfo',
          data: {
            uid: Number(that.$route.query.uid),
          },
          callback: ({ data }) => {
            that.userlist = data
            resolve()
          },
        })
      })
      promise.then(() => {
        that.getGroupList()
      })
    },

    //拉黑
    defriend() {
      const that = this
      that.$http({
        name: 'Defriend',
        url: '/User/defriend',
        data: {
          uids: Number(that.$route.query.uid),
          blackout_reason: '',
        },
        callback() {
          // that.$router.go(-1)
        },
      })
    },
    //取消绑定
    ubind() {
      this.$confirm('是否取消绑定设备？取消后学员可重新绑定！', '取消绑定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.$http({
            name: 'unbind',
            url: '/pcUser/unbind',
            data: {
              uid: Number(this.$route.query.uid),
            },
            callback: ({ code }) => {
              if (code == 200) {
                this.getUserInfo1()
              }
            },
          })
        })
        .catch(() => {})
    },
    changeTabs() {
      // this.$nextTick(() => {
      if (this.activeName == 'one') {
        this.type = 5
      } else if (this.activeName == 'two') {
        this.type = 1
      } else if (this.activeName == 'third') {
        this.type = 2
      }
      // })
    },

    // 编辑用户信息
    editUserInfo() {
      this.edit = _.merge({}, this.userlist, {
        uid: Number(this.$route.query.uid),
        area: this.userlist.area.split(' '),
      })
      this.dialogVisible = true
    },

    // 保存编辑学员信息
    saveUserInfo() {
      this.$refs.editUserInfo.validate((valid) => {
        if (valid) {
          this.$http({
            url: '/pcUser/userInfoEdit',
            data: this.edit,
            callback: () => {
              this.getUserInfo1()
              this.dialogVisible = false
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.dialogVisible {
  .student {
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
  }

  /deep/ .el-col {
    display: flex;
    align-items: center !important;
  }

  /deep/.el-dialog__header {
    background: rgba(245, 245, 245, 1);
    padding: 14px 0px 14px 20px;
  }
  /deep/ .el-dialog__headerbtn .el-dialog__close {
    transform: translateY(-5px);
  }
  /deep/.el-dialog__body {
    padding: 20px 20px 0 20px;
    .el-row {
      margin-bottom: 20px;
      font-size: 14px;
      display: flex;
      align-items: center;
      /deep/.el-cascader {
        width: 550px;
      }

      .select {
        .el-input {
          position: relative;
          .el-input__inner {
            cursor: pointer;
          }
        }
      }
    }
  }
  /deep/.el-dialog__footer {
    text-align: center;
  }
}
.active {
  background: #0aa29bff;
  color: white;
}
/deep/ .el-tag.el-tag--success {
  width: 68px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid rgba(10, 162, 155, 1);
  color: rgba(10, 162, 155, 1);
  background: #fff;
}
/deep/ .el-tag.el-tag--info.el-tag--plain {
  margin: 5px;
}
/deep/ .el-tag.el-tag--warning.el-tag--plain {
  text-align: center;
  border: 1px solid rgba(10, 162, 155, 1);
  margin: 5px;
  background: rgba(10, 162, 155, 1);
  border-radius: 4px;
  color: white;
}
.top1 {
  padding: 7px;
  background-color: #fff;
  margin: 20px 20px 0;
  .top2 {
    // background-color: red;
    margin: 20px;
    .aling1 {
      float: left;
      display: flex;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
      }
      .tu1 {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: grey;
        float: left;
        position: relative;
        .changePhotoimg {
          position: absolute;
          top: 0;
          height: 60px !important;
          width: 50px !important;
        }
        .iconphoto {
          position: absolute;
          height: 26px;
          width: 26px;
          border-radius: 50%;
          bottom: -11px;
          left: 50%;
          transform: translateX(-50%);
        }
        .avatars {
          position: absolute;
          width: 51px;
          height: 50px;
          top: -40px;
          margin: 41px auto 31px auto;
          border-radius: 50%;
          margin: 40px 50%;
          transform: translateX(-50%);
          img {
            width: 100%;
            height: 100%;
          }
          .upImg {
            position: absolute;
            width: 26px;
            height: 26px;
            bottom: -13px;
            left: 50%;
            transform: translateX(-50%);
          }
          .uploadBtn {
            display: none;
          }
        }
      }
      .student_name {
        background-color: #fff;
        float: left;
        margin-left: 14px;
        .zi1 {
          font-size: 16px;
          color: rgba(51, 51, 51, 1);
        }
        .zi2 {
          margin-top: 6px;
          color: rgba(153, 153, 153, 1);
          font-size: 12px;
        }
      }
    }

    .right {
      float: right;
      margin-top: 5px;
    }
    .bottom1 {
      // background-color: red;
      margin-top: 28px;
      .el-row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .el-col {
          // }
          ul {
            float: left;
            li {
              float: left;
              font-size: 14px;
              color: rgba(243, 152, 2, 1);
              line-height: 19px;
              margin-right: 20px;
            }
          }
          .el-button {
            font-size: 14px;
            padding: 0;
            margin: 0;
            float: left;
          }
          .zi3 {
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            width: 100px;
            min-width: 100px;
          }
          .zi4 {
            margin-left: 20px;
            flex: 1;
            color: rgba(51, 51, 51, 1);
            font-size: 14px;
            /deep/ .el-select {
              .el-input__inner {
                border: 0;
                padding: 0;
                margin: 0;
              }
            }
          }
        }
      }
      .el-row:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
.box-card {
  background: rgba(255, 255, 255, 1);
  margin: 20px;
  padding: 20px;
  padding-top: 14px;
}
// .el-button--text {
//   color: #1b9d97;
// }
/deep/.el-card__body {
  padding: 0px !important;
}
/deep/ .el-tabs__nav-wrap::after {
  height: 1px;
}
/deep/ .el-tabs__header.is-top {
  margin: 0px;
}
/deep/ .el-tabs__item.is-active {
  color: #0aa29b;
}
/deep/ .el-tabs__item:hover {
  color: #0aa29b;
}

/deep/ .el-tabs__active-bar {
  background-color: #0aa29b;
  height: 1px;
}
// /deep/ .el-tabs.el-tabs--top {
// }
/deep/ #tab-first {
  padding-left: 20px;
}
/deep/ #tab-one {
  padding-left: 20px !important;
}
/deep/ .placeholder333 {
  input::-webkit-input-placeholder {
    color: #333;
  }
}
.textHidden {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
