<template>
  <div>
    <template>
      <div style="margin:20px 0">
        <pagination2 ref="mychild" :option="post2" url="/PcUser/getReviewList">
          <template
            v-slot:default="{
              tableData: {
                hide_count,
                show_count,
                review_count,
                all_count,
                reviewList
              }
            }"
          >
            <div class="top1">
              <el-row>
                <el-col :span="6">
                  总评论
                </el-col>
                <el-col :span="6">
                  显示中
                </el-col>
                <el-col :span="6">隐藏中</el-col>
                <el-col :span="6">审核中</el-col>
              </el-row>
              <el-row>
                <el-col :span="6">{{ all_count || 0 }}</el-col>
                <el-col :span="6">{{ show_count || 0 }}</el-col>
                <el-col :span="6">{{ hide_count || 0 }}</el-col>
                <el-col :span="6">{{ review_count || 0 }}</el-col>
              </el-row>
            </div>
            <div class="select">
              <el-select
                v-model="post2.status"
                style="width:120px"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-table
              @selection-change="handleSelectionChange"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333'
              }"
              :data="reviewList"
              style="width: 100%"
            >
              <el-table-column
                type="selection"
                width="50"
                v-bind:selectable="chkstu"
              />
              <el-table-column label="评论内容" prop="content" />
              <el-table-column label="评论课程" prop="name" />
              <el-table-column label="评论时间">
                <template slot-scope="scope">
                  {{
                    scope.row.createtime | formatTimeStamp('yyyy-MM-dd hh:mm')
                  }}
                </template>
              </el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">
                  <!-- 显示中，隐藏中 -->
                  <span v-if="scope.row.status == 1">审核中</span>
                  <span v-if="scope.row.status == 2">显示中</span>
                  <span v-if="scope.row.status == 3">隐藏中</span>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <div v-if="scope.row.delete_time == 0">
                    <el-button
                      type="text"
                      v-if="scope.row.status == 2"
                      @click="hideshow(scope.row, 1)"
                    >
                      隐藏评论
                    </el-button>
                    <el-button
                      v-if="scope.row.status == 3 || scope.row.status == 1"
                      type="text"
                      @click="hideshow(scope.row, 2)"
                    >
                      显示评论
                    </el-button>
                    <span
                      style="vertical-align: middle; display: inline-block;padding:0px 10px;padding-bottom:3px; color:#1B9D97"
                    >
                      |
                    </span>
                    <el-button type="text" @click="del(scope.row)">
                      删除
                    </el-button>
                  </div>
                  <div v-else>
                    <el-button type="text" @click="del(scope.row)">
                      删除
                    </el-button>
                  </div>
                </template>
                <!-- <template
                  slot-scope="scope"
                  v-if="scope.row.status == 1 || scope.row.delete_time > 0"
                >
                  <el-button type="text">删除</el-button>
                </template> -->
              </el-table-column>
            </el-table>
          </template>
          <template v-slot:footer="">
            <div class="handle">
              <div class="zi1" style="float:left">
                已选择{{ checklist.length }}条
              </div>
              <el-button @click="hideshows(3)">批量隐藏</el-button>
              <el-button @click="hideshows(2)">批量显示</el-button>
              <el-button @click="dels">批量删除</el-button>
            </div>
          </template>
        </pagination2>
      </div>
    </template>
  </div>
</template>

<script>
// import background from '@/mixin/background'

export default {
  components: {},

  // mixins: [background],

  data() {
    return {
      post2: {
        uid: '',
        status: 0
      },
      checklist: [], //选中的值的列表
      options: [
        {
          value: 0,
          label: '全部状态'
        },
        {
          value: 1,
          label: '审核中'
        },
        {
          value: 2,
          label: '显示中'
        },
        {
          value: 3,
          label: '隐藏中'
        }
      ]
    }
  },
  created() {
    this.post2.uid = this.$route.query.uid
  },
  methods: {
    handleSelectionChange(val) {
      this.checklist = val
    },
    chkstu(row) {
      return row.delete_time == 0 ? true : false
    },

    //批量删除
    dels() {
      if (this.checklist.length > 0) {
        this.$confirm('是否删除当前选中的评论内容？', '删除评论', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          let ids = this.checklist.map(m => m.review_id)
          this.$http({
            url: '/PcUser/delCourseReview',
            data: {
              ids: ids
            },
            callback: ({ code }) => {
              if (code == 200) {
                this.$message({
                  message: '删除成功',
                  type: 'success'
                })
                this.$refs.mychild.reset()
              }
            }
          })
        })
      } else {
        this.$root.prompt({
          msg: '请先选择内容，方可批量操作',
          type: 'info'
        })
      //   if (this.msg) {
      //     this.msg.close()
      //   }
        
      // this.msg = this.$message({
      //     showClose: true,
      //     message: '这是一条消息提示'
      //   })
      }
    },
    //删除评论
    del(val) {
      this.$confirm('是否删除当前选中的评论内容？', '删除评论', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$http({
          url: '/PcUser/delCourseReview',
          data: {
            ids: [val.review_id]
          },
          callback: ({ code }) => {
            if (code == 200) {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.$refs.mychild.reset()
            }
          }
        })
      })
    },
    //隐藏显示评论
    hideshow(val, val1) {
      this.$http({
        url: '/PcUser/statusCourseReview',
        data: {
          ids: [val.review_id],
          status: val.status == 2 ? 3 : 2
        },
        callback: ({ code }) => {
          if (code == 200) {
            if (val1 == 1) {
              this.$message({
                message: '隐藏评论成功',
                type: 'success'
              })
            } else {
              this.$message({
                message: '显示评论成功',
                type: 'success'
              })
            }
            this.$refs.mychild.reset()
          }
        }
      })
    },
    //批量隐藏显示评论
    hideshows(val) {
      if (this.checklist.length > 0) {
        let ids = this.checklist.map(m => m.review_id)
        this.$http({
          url: '/PcUser/statusCourseReview',
          data: {
            ids: ids,
            status: val
          },
          callback: ({ code }) => {
            if (code == 200) {
              if (val == 3) {
                this.$message({
                  message: '批量隐藏评论成功',
                  type: 'success'
                })
              } else {
                this.$message({
                  message: '批量显示评论成功',
                  type: 'success'
                })
              }
              this.$refs.mychild.reset()
            }
          }
        })
      } else {
        this.$root.prompt({
          msg: '请先选择内容，方可批量操作',
          type: 'info'
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.top1 {
  height: 67px;
  background-color: #fff;
  // margin: 30px 20px 0;
  margin-top: 30px;
  box-sizing: border-box;
  overflow: hidden;
  .el-row:first-child {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    line-height: 17px;
  }
  .el-row:last-child {
    margin-top: 10px;
    font-size: 22px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 29px;
  }
}
.handle {
  margin-top: 20px;
  .zi1 {
    height: 19px;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    line-height: 19px;
    margin-top: 8px;
    margin-right: 20px;
  }
  .el-button {
    width: 120px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid rgba(221, 221, 221, 1);
    padding: 0;
  }
}

.select {
  .el-select {
    float: right;
    margin-bottom: 20px;
  }
  /deep/ .el-input--suffix .el-input__inner {
    height: 36px;
  }
}
</style>
