<template>
  <div class="learningstatistics">
    <div class="top">
      <el-row>
        <el-col :span="5">
          <div class="flex">
            <div>今日学习时长</div>
            <helpIcon
              style="margin-left: 6px"
              prompt="包含今日全部课程类型的学习时长，点击播放才算学习行为，仅进入页面不算"
            ></helpIcon>
          </div>
          <div class="number">
            {{ datalist.today_duration | formatTimeLength }}
          </div>
        </el-col>
        <el-col :span="5">
          <div class="flex">
            <div>累计学习时长</div>
            <helpIcon
              style="margin-left: 6px"
              prompt="学员注册至今累计学习时长，包含全部课程类型的学习时长，点击播放才算学习行为，仅进入页面不算"
            ></helpIcon>
          </div>
          <div class="number">{{ datalist.duration | formatTimeLength }}</div>
        </el-col>
        <el-col :span="5">
          <div class="flex">
            <div>总学习课程</div>
          </div>
          <div class="number">{{ datalist.course_count || 0 }}</div>
        </el-col>
        <el-col :span="5">
          <div class="flex">
            <div>总学习章节</div>
          </div>
          <div class="number">{{ datalist.schedule_count || 0 }}</div>
        </el-col>
        <el-col :span="4">
          <div class="flex">
            <div>总学习直播</div>
            <helpIcon
              style="margin-left: 6px"
              prompt="包括公开课和智能直播"
            ></helpIcon>
          </div>
          <div class="number">{{ datalist.live_count || 0 }}</div>
        </el-col>
      </el-row>
      <div class="right">
        <el-date-picker
          style="float: right"
          @change="picktime"
          v-model="time"
          type="daterange"
          align="right"
          unlink-panels
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :pickerOptions="pickerOptions"
        ></el-date-picker>
        <el-radio-group
          style="float: right; margin: 0 20px"
          v-model="tabPosition"
          @change="tabPosition2"
        >
          <el-radio-button label="1">最近7天</el-radio-button>
          <el-radio-button label="2">最近30天</el-radio-button>
        </el-radio-group>
      </div>
      <div class="center">
        <div class="divider"></div>
        <div class="title1">学习时长概况</div>

        <div style="clear: both"></div>
        <div class="font"></div>
        <div class="line-chart">
          <lineChart :istime="true" :options="saleIncrementData"></lineChart>
        </div>
      </div>
      <div class="center">
        <div class="divider"></div>
        <div class="title">
          学习终端次数占比
          <el-select
            v-model="value"
            style="width: 170px; float: right"
            placeholder="请选择"
            @change="select"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div style="clear: both"></div>
        <div class="pieChart">
          <pieChart
            v-if="value == 4 || value == 5 || value == 1 || value==6 || value==7"
            :pieData="list1"
          ></pieChart>
          <pieChart v-else :pieData="list2"></pieChart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helpIcon from '@/components/helpIcon'
import pieChart from '@/components/dataAnalysis/pieChart'
import lineChart from '@/components/dataAnalysis/line'

export default {
  components: {
    helpIcon,
    lineChart,
    pieChart,
  },
  data() {
    return {
      datalist: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 86400000
        },
      },
      selection: '',
      time: '',
      tabPosition: 1,
      list1: [],
      list2: [],
      saleIncrementData: {},
      post1: {
        gid: this.$route.query.gid,
        uid: this.$route.query.uid,
        area_type: 1,
        startTime: '',
        endTime: '',
        course_type: 1,
      },
      options: [
        {
          value: 1,
          label: '直播课-普通模式',
        },
        {
          value: 2,
          label: '直播课-极速模式',
        },
        {
          value: 3,
          label: '小班直播',
        },
        {
          value: 5,
          label: '录播',
        },
        {
          value: 4,
          label: '直播课回放',
        },
        {
          value: 6,
          label: '直播',
        },
        {
          value: 7,
          label: '直播回放',
        },
      ],
      value: 1,
    }
  },
  created() {
    this.getdate()
    this.getdate2()
  },
  methods: {
    select(val) {
      this.post1.course_type = val
      this.value = val
      this.getdate2()
    },
    tabPosition2(val) {
      this.time = ''
      this.post1.area_type = val
      this.post1.startTime = ''
      this.post1.endTime = ''
      this.getdate2()
    },
    picktime(val) {
      try {
        this.post1.startTime = val[0] / 1000
        this.post1.endTime = val[1] / 1000 + 86399
        this.tabPosition = ''
      } catch {
        this.post1.startTime = ''
        this.post1.startTime = ''
        this.tabPosition = 1
      }
      this.post1.area_type = val ? 3 : 1
      this.getdate2()
    },

    getdate() {
      this.$http({
        url: '/PcUser/learnStatistics',
        data: {
          //学员分组--数据统计
          gid: this.$route.query.gid,
          //学员详情
          uid: this.$route.query.uid,
        },
        callback: ({ data, code }) => {
          if (code == 200) {
            this.datalist = data
          }
        },
      })
    },

    getdate2() {
      this.$http({
        url: '/PcUser/learnSurvey',
        data: this.post1,
        callback: ({ data, code }) => {
          if (code == 200) {
            this.saleIncrementData = {
              legend: {
                left: 0,
                data: [
                  '直播课-普通模式',
                  '直播课-极速模式',
                  '小班直播',
                  '录播',
                  '直播课回放',
                  '直播',
                  '直播回放',
                ],
              },
              xAxis: {
                data: data.list.map((item) => item.date),
              },
              yAxis: {
                minInterval: null,
              },
              // seriesName  value
              tooltip: {
                formatter(params) {
                  let t = ''
                  params.forEach((item) => {
                    const formatSecondsToTime = (seconds) => {
                      const hours = Math.floor(seconds / 3600);
                      const minutes = Math.floor((seconds % 3600) / 60);
                      const remainingSeconds = seconds % 60;
                      // 补零操作，确保单个数字的时间单位前有0
                      const padZero = (num) => (num < 10 ? '0' : '') + num;
                      if(hours==0&&minutes>0) return `${minutes}分${padZero(remainingSeconds)}秒`;
                      if(hours==0&&minutes==0) return `${remainingSeconds}秒`;
                      return `${hours}时${padZero(minutes)}分${padZero(remainingSeconds)}秒`;
                    }
                    t += `${item.seriesName}：${formatSecondsToTime(item.value)}<br />`
                  })
                  return t
                },
              },
              series: [
                {
                  name: '直播课-普通模式',
                  type: 'line',
                  data: data.list.map((item) => item.live),
                  lineStyle: {
                    color: '#1A66E9',
                  },
                  itemStyle: {
                    color: '#1A66E9',
                    borderWidth: 4,
                  },
                  smooth: true,
                },
                {
                  name: '直播课-极速模式',
                  type: 'line',
                  data: data.list.map((item) => item.fastLive),
                  lineStyle: {
                    color: '#FFD84D',
                  },
                  itemStyle: {
                    color: '#FFD84D',
                    borderWidth: 4,
                  },
                  smooth: true,
                },
                {
                  name: '小班直播',
                  type: 'line',
                  data: data.list.map((item) => item.private),
                  lineStyle: {
                    color: '#3AC066',
                  },
                  itemStyle: {
                    color: '#3AC066',
                    borderWidth: 4,
                  },
                  smooth: true,
                },
                {
                  name: '录播',
                  type: 'line',
                  data: data.list.map((item) => item.video),
                  lineStyle: {
                    color: '#FF3535',
                  },
                  itemStyle: {
                    color: '#FF3535',
                    borderWidth: 4,
                  },
                  smooth: true,
                },
                {
                  name: '直播课回放',
                  type: 'line',
                  data: data.list.map((item) => item.playback),
                  lineStyle: {
                    color: '#1B9D97',
                  },
                  itemStyle: {
                    color: '#1B9D97',
                    borderWidth: 4,
                  },
                  smooth: true,
                },
                {
                  name: '直播',
                  type: 'line',
                  data: data.list.map((item) => item.openClass),
                  lineStyle: {
                    color: '#DB9F97',
                  },
                  itemStyle: {
                    color: '#DB9F97',
                    borderWidth: 4,
                  },
                  smooth: true,
                },
                {
                  name: '直播回放',
                  type: 'line',
                  data: data.list.map((item) => item.openClassPlayback),
                  lineStyle: {
                    color: '#1F9997',
                  },
                  itemStyle: {
                    color: '#1F9997',
                    borderWidth: 4,
                  },
                  smooth: true,
                },
              ],
            }

            this.list1 = [
              {
                value: data.h5_num,
                name: '微信H5',
                itemStyle: { color: '#F8B832' },
              },
              {
                value: data.xcx_num,
                name: '小程序',
                itemStyle: { color: '#0098FF' },
              },
              {
                value: data.pc_num,
                name: '电脑',
                itemStyle: { color: '#00D966' },
              },
            ]
            this.list2 = [
              {
                value: data.xcx_num,
                name: '小程序',
                itemStyle: { color: '#F8B832' },
              },
              {
                value: data.pc_num,
                name: '电脑',
                itemStyle: { color: '#00D966' },
              },
            ]
          }
        },
      })
    },

  },
}
</script>
<style lang="scss" scoped>
.learningstatistics {
  margin-top: 30px;
  .digitalDisplay-popper {
    padding: 7px 14px;
  }
  .popover {
    padding: 0px;
  }
  .top {
    ::v-deep .el-row {
      .el-col {
        .flex {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 13px;
          color: rgba(102, 102, 102, 1);
          line-height: 17px;
        }
        .number {
          font-size: 22px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          line-height: 29px;
          margin-top: 9px;
        }
      }
    }
    .right {
      // float: right;
      height: 41px;
      margin-top: 50px;
      // width: 100%;
      padding: 11px 20px 0 0;
      background-color: #f5f5f5;
      margin-bottom: 19px;
    }
    .center {
      .divider {
        width: 2px;
        height: 20px;
        background-color: $color;
        margin-right: 10px;
        float: left;
      }
      .title {
        font-size: 18px;
        margin-bottom: 34px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        width: 98%;
        margin-top: 30px;
        line-height: 24px;
      }
      .title1 {
        font-size: 18px;
        margin-bottom: 34px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        width: 98%;
        line-height: 24px;
      }
    }
  }
  ::v-deep .el-input,
  ::v-deep .el-range-input {
    font-size: 12px;
    line-height: 30px;
  }
  ::v-deep .el-radio-button__inner,
  ::v-deep .el-select-dropdown__item {
    font-size: 12px;
  }

  ::v-deep .el-input__inner {
    height: 30px;
  }

  ::v-deep .el-select .el-input__icon {
    line-height: 30px;
    padding-top: 1px;
  }
  ::v-deep .el-radio-button__inner {
    height: 30px;
    font-size: 12px;
    width: 79px;
    padding: 9px 0px;
    text-align: center;
    // line-height: 11px;
  }
  ::v-deep .select-record .el-input__icon {
    line-height: 35px;
  }
  ::v-deep .el-range-separator,
  ::v-deep .el-date-editor .el-range__icon,
  ::v-deep .el-date-editor .el-range__close-icon {
    line-height: 29px;
  }
  ::v-deep .el-date-editor {
    width: 212px;
    height: 30px;
    padding: 0;
    padding-left: 14px;
  }
  .line-chart {
    height: 305px;
    position: relative;
  }
  .pieChart {
    height: 305px;
    position: relative;
  }
}
</style>
